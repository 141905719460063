import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../assets/js/canvasjs.min';
import { ApiServices } from '../shared/api.services';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  title = "Dashboard";
  message;
  support_stats: any;
  thing_types = [];
  dashboard_stats = {};
  survey_report = [];
  // Events related
  constructor(private apiServices: ApiServices) {

  }

  ngOnInit() {
    this.getStats();
    this.getSurveyReport();
    //Push Notifications
    if (this.apiServices.isLoggedIn) {
    }
  }

  getStats() {
    this.apiServices.getStats({}).subscribe(stats => {
      if (stats['status'] == 'success' && stats['data']['support_stats']) {
        this.support_stats = stats['data']['support_stats'];
        this.thing_types = stats['data']['thing_types'];
        this.dashboard_stats = stats['data']['dashboard_stats'];
        /* setTimeout(() => {
          let dataPoints = [];
          let pieChart = [];
          for (var stat in this.support_stats) {
            dataPoints.push({ y: this.support_stats[stat]['total'], label: "Total", color: "#42A5F5" });
            dataPoints.push({ y: this.support_stats[stat]['solved'], label: "Solved", color: "#66BB6A" });
            dataPoints.push({ y: this.support_stats[stat]['unsolved'], label: "Unsolved", color: "#ef5350" });
            this.columChart("chartContainer" + stat, dataPoints, stat.charAt(0).toUpperCase() + stat.substring(1));
            dataPoints = [];
            pieChart.push({ y: this.support_stats[stat]['total'], label: stat.charAt(0).toUpperCase() + stat.substring(1) })
          };
          this.pieChart("pieChart", pieChart, "Category Wise Enquiries");
        }, 100); */
      }
    });
  }

  getSurveyReport() {
    this.apiServices.getSurveyReport().subscribe(report => {
      if (report['status'] == "success") {
        this.survey_report = report['data'];
        setTimeout(() => {
          for (var question = 0; question < (this.survey_report).length; question++) {
            this.createPieChart("chartContainer" + this.survey_report[question]['_id'], this.survey_report[question]['surveyResponse'], this.survey_report[question]['question']);
          }
        }, 100);
      }
    })
  }


  columChart(chartContainer, dataPoints, title) {
    let chart = new CanvasJS.Chart(chartContainer, {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: title
      },
      data: [{
        type: "column",
        dataPoints: dataPoints
      }]
    });
    chart.render();
  }

  pieChart(chartContainer, dataPoints, title) {
    let chart = new CanvasJS.Chart(chartContainer, {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: title
      },
      data: [{
        type: "pie",
        startAngle: 25,
        toolTipContent: "<b>{label}</b>: {y} enquiries",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}",
        dataPoints: dataPoints
      }]
    });
    chart.render();
  }

  createPieChart(chartContainer, dataPoints, title) {
    let chart = new CanvasJS.Chart(chartContainer, {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: title
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "<b>{name}</b>: {y} (#percent%)",
        indexLabel: "{name} - #percent%",
        dataPoints: dataPoints
      }]
    });

    chart.render();
  }
}
