import { Component, OnInit } from '@angular/core';
import { TranslateService } from './shared/translate/translate.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  language: string = "en";

  constructor(private translate: TranslateService) { }

  setLang(lang: string) {
    if (lang === "") {
      this.translate.use(this.language);
      localStorage.setItem('lang', this.language);
    }
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  ngOnInit() {
    if (localStorage.getItem('lang') != undefined && localStorage.getItem('lang') != null && localStorage.getItem('lang') !== "")
      this.setLang(localStorage.getItem('lang'));
    else
      this.setLang(this.language);
  }
}
