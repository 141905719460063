import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiServices } from 'src/app/shared/api.services';
import { ApiSocket } from 'src/app/shared/api.socket';

@Component({
  selector: 'app-chat-child',
  templateUrl: './chat-child.component.html',
  styleUrls: ['./chat-child.component.css']
})
export class ChatChildComponent implements OnInit {
  base_url: string;
  uniq_id: string;
  chat = [];
  userInfo = {};
  thing_type: string;
  thing_info: {};
  message = "";
  isReady = false;
  isDesable = false;
  socket: SocketIOClient.Socket;
  isScroolTop = 0;
  is_solved: boolean = false;
  disableScrollDown = false;
  @ViewChild('scrollMe') private scrollContainer: ElementRef;

  imageMimeTypes = ['image/jpeg', 'image/png'];
  videoMimeTypes = ['video/3gpp', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/quicktime', 'video/webm', 'video/x-m4v', 'video/ms-asf', 'video/x-ms-wmv', 'video/x-msvideo'];
  audioTypes = ['audio/aac', 'audio/ogg'];
  docMimeTypes = ['application/pdf'];

  
  constructor(private route: ActivatedRoute, private apiServices: ApiServices, private apiSocket: ApiSocket) {
    this.base_url = apiServices.base_url;
    this.socket = apiSocket.socket;
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.isReady = false;
          this.uniq_id = params['uniq_id'];
          this.socket.on('support_' + this.uniq_id, (data: any) => {
            this.chat.push(data);
            this.disableScrollDown = false;
            this.scrollToBottom();
          });
          this.getChat();
          this.disableScrollDown = false;;
          this.scrollToBottom();
        }
      );
  }

  getChat() {
    this.apiServices.getChat({ 'uniq_id': this.uniq_id }).subscribe(chat => {
      this.chat = chat['data'];
      if (chat['is_solved'] && chat['is_solved'] == true) {
        this.is_solved = true;
      }

      this.userInfo = chat['userInfo'];
      this.thing_type = chat['thing_type'];
      this.thing_info = chat[this.thing_type];
      this.isReady = true;
    });
  }

  sendMessage() {
    this.isDesable = true;
    let params = {
      'uniq_id': this.uniq_id,
      'message': this.message
    };
    this.apiServices.sendMessage(params).subscribe(res => {
      if (res['status'] === 'success') {
        this.message = "";
        this.isDesable = false;
      }
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    if (this.disableScrollDown) {
      return
    }
    try {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  changeChatSTatus(is_solved: boolean) {
    this.uniq_id
    var params = {
      uniq_id: this.uniq_id,
      is_solved: is_solved
    }

    this.apiServices.changeChatSTatus(params).subscribe(res => {
      if (res['status'] === 'success') {
        this.isDesable = false;
        this.is_solved = is_solved;
      }
    });

  }

}
