import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-default',
  templateUrl: './chat-default.component.html',
  styleUrls: ['./chat-default.component.css']
})
export class ChatDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
