import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  NgForm,
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";

import { ApiServices } from "../shared/api.services";
import { PagerService } from "../services/pager.service";
import { VideoProcessingService } from "../services/video-processing-service";
import { CATCH_ERROR_VAR } from "@angular/compiler/src/output/abstract_emitter";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "app-time-line-list",
  templateUrl: "./time-line-list.component.html",
  styleUrls: ["./time-line-list.component.css"],
})
export class TimeLineListComponent implements OnInit {
  page_title = "Time Line";
  base_url: string = "";
  isAlert = false;
  alertClass = "";
  alertMsg = "";
  modelError = "";

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;

  post_id: string = "";
  posts: any;
  posts_data: {} = {};
  posts_list: any;
  tot_posts: number;

  // Post Type selection
  typeImage: boolean = false;
  typeVideo: boolean = false;
  typeArticle: boolean = false;
  typeYoutube: boolean = false;
  showUpload: boolean = true;

  timeLineForm: FormGroup;
  title: string = "";
  description: string = "";
  contentType: string = "";
  subscriptionType: string = "Free";

  urls = [];
  isVideoUploaded = false;
  public thumbnailData: string;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  // Filters
  filters: any = {};
  postTitle: string = "";
  contentFilter: string = "";
  subscriptionFilter: string = "";
  categoryFilter: string = "";
  productFilter: string = "";

  // Categories
  dropdownList = [];
  selectedSubscriptions = [];
  dropDownSubscriptionSettings = {};

  // Product Plans
  productsList = [];
  selectedProducts = [];
  dropDownProductSettings = {};
  isPaidProduct: Boolean = false;

  @ViewChild("closeBtn") closeBtn: ElementRef;
  @ViewChild("alrtDiv") public alrtDiv: ElementRef;

  public uploader: FileUploader = new FileUploader({
    url: "",
    disableMultipart: true,
  });

  constructor(
    public apiServices: ApiServices,
    private pagerService: PagerService,
    private videoService: VideoProcessingService,
    private fb: FormBuilder
  ) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initForm();
    this.fetchTimelineCategories();
    this.fetchProducts();
    // this.dropDownSubscriptionSettings = {
    //   singleSelection: false,
    //   idField: '_id',
    //   textField: 'name',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   allowSearchFilter: true
    // };
    this.dropDownProductSettings = {
      singleSelection: false,
      text: "Select Plans",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      primaryKey: "_id",
      labelKey: "title",
      searchBy: ["title"],
    };
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  fetchPosts(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchPosts(filters).subscribe((posts) => {
        resolve(posts);
      });
    });
  }

  getPostById(_id: string) {
    delete this.filters.skip;
    delete this.filters.limit;
    this.imgPreview = undefined;
    this.imgPreview1 = undefined;
    this.isModelShow = false;
    if (_id != "") {
      this.isEditable = true;
      this.post_id = _id;
      this.filters["_id"] = this.post_id;
      this.fetchPosts(this.filters).then((post) => {
        this.posts_data = post["data"][0];
        this.showContent(this.posts_data["content_type"]);
        this.initForm();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.contentType = "";
      this.subscriptionType = "";
      this.title = "";
      this.posts_data = {};
      this.isPaidProduct = false;
      this.selectedProducts = [];
      this.showContent("");
      this.initForm();
      this.isModelShow = true;
    }
  }

  private initForm() {
    this.apiServices.clearMessage();
    this.modelError = "";
    // let content_type = { value: "", disabled: false };
    let content_type = this.contentType;
    let subscription_type = "";
    let categoryIds = "";
    this.title = "";
    let thumbnail = "";
    let youtube_link = "";
    this.description = "";
    let activeStatus = "";

    let postUrl = "";
    this.imageName = "";
    this.videoName = "";

    if (this.isEditable) {
      this.contentType = this.posts_data["content_type"];
      this.subscriptionType = subscription_type =
        this.posts_data["subscription_type"];
      // Toggle Product div
      if (this.subscriptionType == "Paid") {
        this.isPaidProduct = true;
        this.selectedProducts = this.posts_data.hasOwnProperty("product_ids")
          ? this.posts_data["product_ids"]
          : [];
      }
      categoryIds = this.posts_data["post_category"];
      // this.selectedSubscriptions = this.posts_data['post_category'];
      this.title = this.posts_data["title"];
      this.description = this.posts_data["description"];
      activeStatus = `${this.posts_data["status"]}`;

      // Set Image
      this.urls.push(this.base_url + this.posts_data["thumbnail"]);
      this.imageName = this.posts_data["thumbnail"];
      if (this.contentType === "Youtubevideo") {
        youtube_link = this.posts_data["youtubeLink"];
      }
      if (this.contentType === "Video" || this.contentType === "Livevideo") {
        if (this.posts_data["postUrl"] != "") {
          this.isVideoUploaded = true;
        }
        this.urls.push(this.base_url + this.posts_data["postUrl"]);
        this.videoName = this.posts_data["postUrl"];
      }
    }
    /* if (this.isEditable) {
      content_type = { value: this.contentType, disabled: true };
    } else {
      content_type = { value: this.contentType, disabled: false };
    } */

    let imagesValidation = [];
    if (
      this.urls.length == 0 &&
      this.contentType !== "Livevideo" &&
      this.contentType !== "Video"
    ) {
      imagesValidation.push(Validators.required);
    }

    let videoValidation = [];
    if (this.urls.length == 0 && this.contentType == "Video") {
      videoValidation.push(Validators.required);
    }

    let planValidation = [];
    if (this.contentType !== "Livevideo") {
      planValidation.push(Validators.required);
    }

    let youTubeValidation = [];
    if (this.contentType === "Youtubevideo") {
      youTubeValidation.push(Validators.required);
    }

    if (this.contentType == "Video") {
      this.timeLineForm = this.fb.group({
        content_type: ["Video", Validators.required],
        subscription_type: [this.subscriptionType, Validators.required],
        product_ids: [this.selectedProducts],
        category: [categoryIds, planValidation],
        title: [this.title, Validators.required],
        description: [this.description, Validators.required],
        status: [activeStatus],
        youtubeLink: [youtube_link, youTubeValidation],
      });
    } else {
      this.timeLineForm = this.fb.group({
        content_type: [content_type, Validators.required],
        subscription_type: [this.subscriptionType, Validators.required],
        product_ids: [this.selectedProducts],
        category: [categoryIds, planValidation],
        title: [this.title, Validators.required],
        description: [this.description, Validators.required],
        status: [activeStatus],
        thumbnail: [thumbnail, imagesValidation],
        youtubeLink: [youtube_link, youTubeValidation],
      });
    }
  }

  showContent(type: string) {
    this.contentType = type;
    if (type === "Image") {
      this.typeImage = true;
      this.typeVideo = false;
      this.typeArticle = true;
      this.typeYoutube = false;
      this.showUpload = true;
      this.initForm();
    } else if (type === "Video") {
      this.typeImage = true;
      this.typeVideo = true;
      this.typeArticle = true;
      this.typeYoutube = false;
      this.showUpload = true;
      this.initForm();
    } else if (type === "Article") {
      this.typeImage = true;
      this.typeVideo = false;
      this.typeArticle = true;
      this.typeYoutube = false;
      this.showUpload = true;
      this.initForm();
    } else if (type === "Livevideo") {
      this.typeImage = true;
      this.typeVideo = true;
      this.typeArticle = false;
      this.typeYoutube = false;
      this.showUpload = false;
      this.initForm();
    } else if (type === "Youtubevideo") {
      this.typeImage = true;
      this.typeVideo = false;
      this.typeArticle = true;
      this.typeYoutube = true;
      this.showUpload = true;
      this.initForm();
    } else {
      this.typeImage = false;
      this.typeVideo = false;
      this.typeArticle = false;
      this.showUpload = false;
      this.initForm();
    }
  }

  changeSubscription(subscription: string) {
    this.isPaidProduct = subscription == "Paid" ? true : false;
    this.subscriptionType = subscription;
  }

  // For Single Image Upload
  selectedFile: any;
  imgPreview: any;
  imageName: string;
  img_ext_err: Boolean = false;
  onImageSelected(event) {
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    let img_ext = event.target.files[0].type.split("/");
    if (img_ext[0] != "image") {
      this.img_ext_err = true;
    } else {
      this.img_ext_err = false;
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        this.imageName = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = (event) => (this.imgPreview = reader.result);
        // reader.readAsDataURL(file);
      }
    }
  }

  // For Single video
  selectedFile1: any;
  imgPreview1: any;
  videoName: string;
  // onVideoSelected(event: any): void {
  //   this.videoService.promptForVideo().then(videoFile => {
  //     return this.videoService.generateThumbnail(videoFile);
  //   }).then(thumbnailData => {
  //     this.imgPreview = thumbnailData;
  //   })
  // }

  public add(): void {
    this.videoService
      .promptForVideo()
      .then((videoFile) => {
        this.selectedFile1 = videoFile;
        this.imgPreview1 = videoFile;
        this.videoName = videoFile.name;
        this.isVideoUploaded = true;
        return this.videoService.generateThumbnail(videoFile);
      })
      .then((thumbnailData) => {
        this.imgPreview = thumbnailData;
        var img = thumbnailData;
        // Convert Base64 image to binary
        var file = this.dataURItoBlob(img);
        const imageFile = new File([file], "sample.jpeg", {
          type: "image/jpeg",
        });
        this.imageName = imageFile.name;
        this.selectedFile = imageFile;
      });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  onTitleSearch(form: NgForm) {
    this.postTitle = form.value.postTitle;
    this.setPage(1, this.pageSize);
  }

  changeContentFilter(event: any) {
    this.contentFilter = event.target.value;
    this.setPage(1, this.pageSize);
  }

  changecategoryFilter(event: any) {
    this.categoryFilter = event.target.value;
    this.setPage(1, this.pageSize);
  }

  changeSubscriptionFilter(event: any) {
    this.subscriptionFilter = event.target.value;
    this.productFilter = "";
    this.setPage(1, this.pageSize);
  }

  changeProductFilter(event: any) {
    this.productFilter = event.target.value;
    this.subscriptionFilter = "Paid";
    this.setPage(1, this.pageSize);
  }

  progress: number = 0;
  onSubmit() {
    this.apiServices.cleanForm(this.timeLineForm, "thumbnail");
    let condition: boolean;
    if (this.contentType == "Video") {
      condition = this.timeLineForm.valid && this.isVideoUploaded;
    } else {
      condition = this.timeLineForm.valid;
    }
    if (condition) {
      this.isFormSubmit = true;
      // show progress bar
      this.scrollTop();

      const fd = new FormData();

      //  Set product Ids
      if (this.subscriptionType == "Paid") {
        let productIds = this.selectedProducts.map((product) => product._id);
        this.timeLineForm.value.product_ids = productIds;
        fd.append("product_ids", this.timeLineForm.value.product_ids);
      }
      /* if (this.isEditable && this.subscriptionType == "Free") {
        this.selectedProducts = [];
        fd.append('product_ids', "");
      } */
      if (this.selectedFile && this.selectedFile.name)
        fd.append("thumbnail", this.selectedFile, this.selectedFile.name);

      if (this.contentType == "Video") {
        if (this.selectedFile1 && this.selectedFile1.name)
          fd.append("postUrl", this.selectedFile1, this.selectedFile1.name);
      }

      if (this.contentType == "Youtubevideo")
        fd.append("youtubeLink", this.timeLineForm.value.youtubeLink);

      fd.append("content_type", this.contentType);
      fd.append("subscription_type", this.subscriptionType);
      fd.append("category_id", this.timeLineForm.value.category);
      fd.append("title", this.timeLineForm.value.title);
      fd.append("description", this.timeLineForm.value.description);

      if (this.isEditable) {
        fd.append("status", this.timeLineForm.value.status);
        this.apiServices.editPost(this.post_id, fd).subscribe(
          (res) => {
            if (res["status"] === "success") {
              this.isFormSubmit = false;
              this.isVideoUploaded = false;
              this.timeLineForm.reset();
              this.setPage(this.pager.currentPage, this.pageSize);
              this.apiServices.sendTextMessage(res["msg"], "success");
              this.colseModel();
              this.scrollTop();
            } else {
              this.isFormSubmit = false;
              this.modelError = res["msg"];
              /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
              this.scrollTop();
            }
          },
          (error) => {
            this.isFormSubmit = false;
            this.modelError = error;
            this.scrollTop();
          }
        );
      } else {
        this.apiServices.createPost(fd).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log("Request has been made!");
                break;
              case HttpEventType.ResponseHeader:
                console.log("Response header has been received!");
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round((event.loaded / event.total) * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
              case HttpEventType.Response:
                console.log("User successfully created!", event.body);
                var res = event.body;
                if (res["status"] === "success") {
                  this.isFormSubmit = false;
                  this.isVideoUploaded = false;
                  this.timeLineForm.reset();
                  this.setPage(this.pager.currentPage, this.pageSize);
                  this.apiServices.sendTextMessage(res["msg"], "success");
                  this.colseModel();
                  this.scrollTop();
                } else {
                  this.isFormSubmit = false;
                  this.modelError = res["msg"];
                  /* this.apiServices.sendTextMessage(res['msg'], 'danger');
                this.colseModel(); */
                  this.scrollTop();
                }
                setTimeout(() => {
                  this.progress = 0;
                }, 1500);
            }
          },
          (error) => {
            this.isFormSubmit = false;
            this.modelError = error;
            this.scrollTop();
          }
        );
      }
    } else {
      this.modelError = "Please fill all the required fields";
      this.scrollTop();
      /* setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      }); */
    }
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    });
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
    this.urls = [];
    this.selectedFile = undefined;
    this.selectedFile1 = undefined;
    this.imageName = "";
    this.videoName = "";
    this.imgPreview = "";
    this.imgPreview1 = "";
    this.img_ext_err = false;
    this.selectedProducts = [];
    this.isPaidProduct = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-success";
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = "alert-danger";
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters["limit"] = pageCount;
      this.filters["skip"] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters["skip"] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    // set filters
    this.filters["title"] = this.postTitle;
    this.filters["content_type"] = this.contentFilter;
    this.filters["subscription_type"] = this.subscriptionFilter;
    this.filters["category_id"] = this.categoryFilter;
    this.filters["product_ids"] = this.productFilter;

    this.fetchPosts(this.filters)
      .then((langdata) => {
        this.posts_list = langdata;
        this.isModelShow = true;
        this.isLoaderShow = true;
        return;
      })
      .then(() => {
        // total count
        this.tot_posts = this.posts_list["total"];
        // users list
        this.posts = this.posts_list["data"];
        // get pager object from service
        this.pager = this.pagerService.getPager(
          this.tot_posts,
          page,
          pageCount
        );
        // get current page of items
        this.pagedItems = this.posts;
        this.pagedItemsLength = this.pagedItems.length;
      });
  }

  resetFilters() {
    this.pageSize = 10;
    this.postTitle = undefined;
    this.contentType = "";
    this.contentFilter = "";
    this.subscriptionFilter = "";
    this.categoryFilter = "";
    this.productFilter = "";
    this.setPage(1, this.pageSize);
  }

  resetForm() {
    this.colseModel();
    this.urls = [];
    this.timeLineForm.markAsUntouched();
  }

  fetchTimelineCategories() {
    this.apiServices.fetchTimelineCtaegories({}).subscribe((res) => {
      if (res["status"] == "success") {
        this.dropdownList = res["data"];
      }
    });
  }

  fetchProducts() {
    this.apiServices.fetchProducts({ status: "true" }).subscribe((res) => {
      if (res["status"] == "success") {
        this.productsList = res["data"];
      }
    });
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
    this.selectedProducts = [];
  }

  /* toggleDisable(ctrlName: string, disable: Boolean) {
    (disable) ? this.timeLineForm.controls[ctrlName].disable() : this.timeLineForm.controls[ctrlName].enable();
  } */
}
