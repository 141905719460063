import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'; // ChangeDetectionStrategy
import { Location } from "@angular/common";
import { OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
/* import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list'; */
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServices } from '../shared/api.services';

export interface DialogData {
  _id: string;
  date: string;
  event_data: any;
}
@Component({
  selector: 'app-full-calendar-test',
  templateUrl: './full-calendar-test.component.html',
  styleUrls: ['./full-calendar-test.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class FullCalendarTestComponent implements OnInit {

  title = 'Full-Calendar';
  options: OptionsInput;
  eventsModel: any;
  modelError = '';

  events_data: any;
  total_events = 0;
  event_data: {} = {};
  curr_month = new Date().getMonth() + 1;
  curr_year = new Date().getFullYear();

  base_url = '';

  @ViewChild('fullcalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(private apiServices: ApiServices,
    public dialog: MatDialog) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.getEvents();
    this.options = {
      editable: true,
      // timeZone: 'local', // the default (unnecessary to specify)
      customButtons: {
        myCustomButton: {
          text: 'custom!',
          click: function () {
            alert('clicked the custom button!');
          }
        }
      },
      header: {
        left: '', // prev,next today myCustomButton
        center: 'title',
        right: 'dayGridMonth', // timeGridWeek,timeGridDay,listWeek
      },
      plugins: [dayGridPlugin, interactionPlugin], // timeGridPlugin, listPlugin
      droppable: true,
      eventLimit: true,
      views: {
        month: {
          eventLimit: 3
        }
      },
    };
  }

  updateHeader() {
    this.options.header = {
      left: 'prev,next myCustomButton',
      center: 'title',
      right: ''
    };
  }

  updateEvents() {
    this.eventsModel = [{
      title: 'Updaten Event',
      start: this.yearMonth + '-08',
      end: this.yearMonth + '-10'
    }];
  }

  get yearMonth(): string {
    const dateObj = new Date();
    return dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
  }

  dateClick(model): void {
    const dialogRef = this.dialog.open(DialogForAddEvent, {
      // width: '80%',
      data: { date: model.dateStr },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getEvents();
    });
  }

  eventClick(model) {
    let params = {
      "_id": model.event.id,
      "month": this.curr_month,
      "year": this.curr_year
    }
    this.apiServices.getEvents(params).subscribe(res => {
      if (res['status'] == "success") {
        this.event_data = res['data'];
        const dialogRef = this.dialog.open(DialogForAddEvent, {
          // width: '100%',
          data: { event_data: this.event_data[0] }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.getEvents();
        });
      }
    });

  }

  eventDrop(info) {
    let _id = info.event.id;
    let params = {
      "start_date": new Date(info.event.start).toISOString(),
      "end_date": new Date(info.event.end).toISOString()
    }
    // Update Event in background
    this.apiServices.dragEvent(_id, params).subscribe(res => {
      if (res['status'] == "success") {
        this.apiServices.sendTextMessage(res['msg'], 'success');
      } else {
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
      this.getEvents();
    });
  }

  onPrevClick() {
    this.prev();
    this.getEvents();
    this.fullCalendar.getApi().prev();
  }

  onNextClick() {
    this.next();
    this.getEvents();
    this.fullCalendar.getApi().next();
  }

  prev() {
    if (this.curr_month == 1) {
      this.curr_month = 12;
      this.curr_year = this.curr_year - 1;
    } else {
      this.curr_month = this.curr_month - 1;
    }
  }

  next() {
    if (this.curr_month == 12) {
      this.curr_month = 1;
      this.curr_year = this.curr_year + 1;
    } else {
      this.curr_month = this.curr_month + 1;
    }
  }

  /*  eventResize(info) {
     alert(info.event.title + " end is now " + info.event.end.toISOString());
   }
 
   eventDragStart(timeSheetEntry, jsEvent, ui, activeView) {
     console.log('event drag start');
   }
 
   eventDragStop(timeSheetEntry, jsEvent, ui, activeView) {
     console.log('event drag end');
   } */

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  getEvents() {
    let params = {
      "month": this.curr_month,
      "year": this.curr_year
    };
    this.apiServices.getEvents(params).subscribe(res => {
      if (res['status'] == "success") {
        this.total_events = res['eventsCount'];
        this.events_data = res['data'];
      } else {
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
    });
  }

}
@Component({
  selector: 'app-full-calendar-test1',
  templateUrl: './add_event_popup.html',
  styleUrls: ['./full-calendar-test.component.scss']
})

export class DialogForAddEvent {

  event_id: string;
  eventsForm: FormGroup;
  isEditable = false;
  isModelShow = false;
  modelError = '';
  color_code = '#83c340';

  // Participants and Speakers
  dropDownSpeakers = [];
  dropDownParticipants = [];
  selectedSpeakers = [];
  selectedParticipants = [];
  dropDownSpeakerSettings = {};
  dropDownParticipantSettings = {};


  // references the #calendar in the template
  @ViewChild('fullcalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(private apiServices: ApiServices,
    public dialogRef: MatDialogRef<DialogForAddEvent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
    this.getSpeakers();
    this.getParticipants();
    this.dropDownParticipantSettings = {
      /* singleSelection: false,
      text: "Select Participants",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      primaryKey: "_id",
      labelKey: "name",
      classes: "myclass custom-class" */
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.dropDownSpeakerSettings = {
      /* singleSelection: false,
      text: "Select Speakers",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      primaryKey: "_id",
      labelKey: "name",
      classes: "myclass custom-class" */
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  private initForm() {

    this.modelError = '';
    let title = '';
    let description = '';
    let location = '';
    let message = '';
    let speakers = this.fb.array([]);
    let participants = this.fb.array([]);
    let start_date = '';
    let end_date = '';
    let color = '#83c340';

    // On Event Click
    if (this.data.hasOwnProperty('event_data')) {
      this.isEditable = true;
      this.event_id = this.data.event_data.id;
      this.color_code = this.data.event_data.color;
      title = this.data.event_data.title;
      description = this.data.event_data.description;
      location = this.data.event_data.location;
      message = this.data.event_data.message;
      this.selectedSpeakers = this.data.event_data.speakers;
      this.selectedParticipants = this.data.event_data.participants;
      start_date = (this.data.event_data.start);
      end_date = (this.data.event_data.end == "1970-01-01T00:00:00.000Z") ? "" : this.data.event_data.end;
      color = this.data.event_data.color;
    }
    // On Date Click
    if (this.data.hasOwnProperty('date')) {
      start_date = this.data.date;
      end_date = this.data.date;
    }

    this.eventsForm = new FormGroup({
      'title': new FormControl(title, Validators.required),
      'location': new FormControl(location),
      'message': new FormControl(message, Validators.required),
      'description': new FormControl(description, Validators.required),
      'speakers': new FormControl(speakers),
      'participants': new FormControl(participants),
      'start_date': new FormControl(start_date, Validators.required),
      'end_date': new FormControl(end_date),
      'color': new FormControl(color)
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  addEvent() {
    if (this.eventsForm.valid == true) {
      // Set speakers
      let speakers = this.selectedSpeakers.map(a => a._id);
      this.eventsForm.value.speakers = speakers;
      // Set participants
      let participants = this.selectedParticipants.map(a => a._id);
      this.eventsForm.value.participants = participants;
      // Set time zones
      this.eventsForm.value.start_date = new Date(this.eventsForm.value.start_date).toISOString();
      this.eventsForm.value.end_date = new Date(this.eventsForm.value.end_date).toISOString();

      this.modelError = '';

      if (this.isEditable) {
        this.apiServices.editEvent(this.event_id, this.eventsForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.onNoClick();
          } else {
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addEvent(this.eventsForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.onNoClick();
          } else {
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      }
    }
    else {
      this.modelError = "Please select all mandatory(*) values!";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  getColorCode(el) {
    this.color_code = el.target.dataset.color;
    this.eventsForm.value.color = el.target.dataset.color;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeEvent(event_id) {
    this.apiServices.deleteEvent(event_id).subscribe(res => {
      if (res['status'] == "success") {
        this.onNoClick();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      } else {
        this.onNoClick();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
    });
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  getSpeakers() {
    this.apiServices.getSpeakers().subscribe(res => {
      if (res['status'] == "success") {
        this.dropDownSpeakers = res['data'];
      }
    });
  }

  getParticipants() {
    this.apiServices.getParticipants().subscribe(res => {
      if (res['status'] == "success") {
        this.dropDownParticipants = res['data'];
      }
    });
  }

  onItemSelect1(item: any) {
    console.log(item);
    // console.log(this.selectedSpeakers);
  }
  OnItemDeSelect1(item: any) {
    console.log(item);
    // console.log(this.selectedSpeakers);
  }
  onSelectAll1(items: any) {
    console.log(items);
  }
  onDeSelectAll1(items: any) {
    console.log(items);
  }

  onItemSelect2(item: any) {
    console.log(item);
    // console.log(this.selectedParticipants);
  }
  OnItemDeSelect2(item: any) {
    console.log(item);
    // console.log(this.selectedParticipants);
  }
  onSelectAll2(items: any) {
    console.log(items);
  }
  onDeSelectAll2(items: any) {
    console.log(items);
  }

}
