import { Component, OnInit } from '@angular/core';
import { ApiServices } from 'src/app/shared/api.services';
import { Router } from '@angular/router';
import { ErrorDataModel } from 'src/app/shared/message/error.data.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private apiServices: ApiServices, private router: Router, private errorData: ErrorDataModel) { }

  ngOnInit() {
    this.apiServices.isLoggedIn.subscribe(data => {
      if (data) {
        this.router.navigate(['/home']);
      }
    });
    this.apiServices.clearMessage();
    if (this.errorData.alerts != undefined) {
      this.errorData.alerts.forEach(alert => {
        this.apiServices.sendTextMessage(alert.message, alert.type);
      });
      this.errorData.alerts = [];
    }
  }

  onForgot(form: NgForm) {
    const email = form.value.email;
    this.apiServices.resetPassword(email).subscribe(
      data => {
        if (data['status'] === 'failure') {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(data['msg'], 'danger');
        } else {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(data['msg'], 'success');
        }
      });
  }

}
