import { Component, OnInit } from '@angular/core';
import { ApiServices } from 'src/app/shared/api.services';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

export interface User{
  password: string;
  confirmPassword: string;
} 

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})


export class ResetPasswordComponent implements OnInit {

  public currentUrl: string = "";
  key: string = "";
  public user: User;

  constructor(private apiServices: ApiServices, private router: Router) { }

  ngOnInit() {

    this.user = {
      password: '',
      confirmPassword: ''
    }

    this.currentUrl = this.router.url;
    var value = this.currentUrl.split("/");
    this.key = value[value.length - 1];
    this.apiServices.validateKey(this.key).subscribe(
      data => {
        if (data['status'] === 'failure') {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(data['msg'], 'danger');
        } else {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(data['msg'], 'success');
        }
      });
  }

  save(model: User, isValid: boolean) {
    // call API to save customer
    console.log(model, isValid);
  }

  onResetPwd(model: User, isValid: boolean) {
    let password = "";
    password = model.password;
    this.apiServices.setNewPassword(this.key, password).subscribe(res => {
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'danger');
        this.router.navigate(['']);
      } else {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
        this.router.navigate(['/signin']);
      }
    })
  }
}
