import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiServices } from '../shared/api.services';
import { PagerService } from '../services/pager.service';
import { maxLength } from './maxlength.validator';
import { Subject, AsyncSubject } from 'rxjs';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

declare var tinyMCE: any;

@Component({
  selector: 'app-timeline-categories',
  templateUrl: './timeline-categories.component.html',
  styleUrls: ['./table_grad.scss'],
  // styleUrls: ['./timeline-categories.component.css']
})

export class TimelineCategoriesComponent implements OnInit {
  title = "Categories";
  base_url: string;
  isAlert = false;
  alertClass = '';
  alertMsg = '';
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  category_id: string;

  categoryForm: FormGroup;
  dimensions = {};
  dimensionType: string = "default";
  /* cust_width: number = 100;
  cust_height: number = 100; */
  apiKey = '';

  categories;
  categories_data: {} = {};
  category_list: any;
  tot_categories: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  // Product Plans
  productsList = [];
  selectedProducts = [];
  dropDownProductSettings = {};

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  private editorSubject: Subject<any> = new AsyncSubject();

  allowFreeUsers: boolean = true;

  constructor(public apiServices: ApiServices, private pagerService: PagerService) {
    this.base_url = apiServices.base_url;
    this.apiKey = apiServices.tinyApiKey;
  }

  ngOnInit() {
    this.initFrom();
    this.fetchProducts();
    this.dropDownProductSettings = {
      singleSelection: false,
      text: "Select Plans",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
      primaryKey: "_id",
      labelKey: "title",
      searchBy: ["title"]
    }
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  getCategories(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getCategories(filters).subscribe(categories => {
        this.isLoaderShow = true;
        resolve(categories);
      })
    });
  }

  getCategoryById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    this.allowFreeUsers = true;
    this.selectedProducts = [];
    if (_id != '') {
      this.isEditable = true;
      this.category_id = _id;
      let params = {};
      params['_id'] = this.category_id;
      this.filters['_id'] = this.category_id;
      this.getCategories(params).then(category_info => {
        this.categories_data = category_info['data'][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.categories_data = {};
      this.dimensionType = "";
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.iconSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }


  private initFrom() {
    this.modelError = '';
    let cat_name = '';
    // let description = '';
    let d_type = 'default';
    let cust_width = 100;
    let cust_height = 100;
    let activeStatus = '';
    let allowFreeUsers = '';

    if (this.isEditable) {
      let category = this.categories_data;
      cat_name = category['name'];
      // description = category['description'];
      allowFreeUsers = category.hasOwnProperty('allow_free_users') ? `${category['allow_free_users']}` : 'false';
      this.allowFreeUsers = (allowFreeUsers == "true") ? true : false;
      this.selectedProducts = category.hasOwnProperty("product_ids") ? category["product_ids"] : [];
      this.dimensions = category.hasOwnProperty('dimensions') ? category['dimensions'] : {};
      this.dimensionType = d_type = this.dimensions.hasOwnProperty('dtype') ? this.dimensions['dtype'] : d_type;
      if (d_type == "custom") {
        cust_width = this.dimensions['width'];
        cust_height = this.dimensions['height'];
      }
      activeStatus = `${category['status']}`;
    }
    this.categoryForm = new FormGroup({
      'cat_name': new FormControl(cat_name, Validators.required, maxLength(this.editorSubject, 25)), // 
      'allow_free_users': new FormControl(allowFreeUsers),
      'product_ids': new FormControl(this.selectedProducts),
      'dimensions': new FormControl(d_type, Validators.required),
      // 'description': new FormControl(description, Validators.required),
      'cust_width': new FormControl(cust_width),
      'cust_height': new FormControl(cust_height),
      'status': new FormControl(activeStatus)
    });
  }

  get categoryFormControl() {
    return this.categoryForm.controls;
  }

  onSubmit() {
    var wordcount = tinyMCE.activeEditor.plugins.wordcount;
    this.apiServices.cleanForm(this.categoryForm);
    if (this.categoryForm.valid == true && wordcount.body.getWordCount() > 0) {
      this.isFormSubmit = true;
      this.modelError = '';
      const fd = new FormData();
      fd.append('name', this.categoryForm.get('cat_name').value);
      //  Set product Ids
      let productIds = this.selectedProducts.map(product => product._id);
      this.categoryForm.value.product_ids = productIds;
      fd.append('allow_free_users', this.categoryForm.get('allow_free_users').value);
      if (!this.allowFreeUsers) {
        if (this.selectedProducts.length > 0) {
          fd.append('product_ids', this.categoryForm.value.product_ids);
        } else {
          this.modelError = "Please select atleast one plan";
          this.isFormSubmit = false;
          return;
        }
      }
      // set dimensions
      if (this.categoryForm.get('dimensions').value == "custom") {
        this.dimensions = {
          dtype: "custom",
          width: this.categoryForm.get('cust_width').value,
          height: this.categoryForm.get('cust_height').value
        };
      } else {
        this.dimensions = {
          dtype: "default"
        };
      }
      fd.append('dimensions', JSON.stringify(this.dimensions));
      if (this.isEditable) {
        fd.append('status', this.categoryForm.get('status').value);
        this.apiServices.editCategory(this.category_id, fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
            // this.successAlert(res['msg']);
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
            // this.errorAlert(res['msg']);
          }
        },
          error => {
            this.errorAlert(error);
          });
      } else {
        this.apiServices.addCategory(fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.categoryForm.reset();
            this.setPage(this.pager.currentPage, this.pageSize);
            // this.successAlert(res['msg']);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            // this.errorAlert(res['msg']);
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
          }
        },
          error => {
            this.errorAlert(error);
          });
      }
    } else {
      this.modelError = "Please fill all the required fields";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;

  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-success';
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-danger';
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.getCategories(this.filters).then(categories => {
      this.category_list = categories;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        // total count
        this.tot_categories = this.category_list['total'];
        // users list
        this.categories = this.category_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_categories, page, pageCount);
        // get current page of items
        this.pagedItems = this.categories;
        this.pagedItemsLength = this.pagedItems.length;
      })
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  setDimensions(dimension: string) {
    this.dimensionType = dimension;
    this.dimensions = {};
    if (dimension == "custom") {
      this.categoryForm.get('cust_width').setValidators([Validators.required, Validators.min(100), Validators.max(300)]);
      this.categoryForm.get('cust_height').setValidators([Validators.required, Validators.min(100), Validators.max(300)]);
    } else {
      this.categoryForm.get('cust_width').clearValidators();
      this.categoryForm.get('cust_height').clearValidators();
      this.categoryForm.get('cust_width').updateValueAndValidity();
      this.categoryForm.get('cust_height').updateValueAndValidity();
    }
  }

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  fetchProducts() {
    this.apiServices.fetchProducts({ 'status': 'true' }).subscribe(res => {
      if (res['status'] == "success") {
        this.productsList = res['data'];
      }
    })
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex != event.currentIndex) {
      this.isLoaderShow = false;
      let source_doc = this.pagedItems[event.previousIndex]['_id'];
      let destination_doc = this.pagedItems[event.currentIndex]['_id'];
      this.apiServices._timelineCategorySwap({ source_doc, destination_doc }).subscribe((res) => {
        if (res['status'] == "success") {
          this.setPage(this.pager.currentPage, this.pageSize);
          this.apiServices.sendTextMessage(res['msg'], 'success');
        } else {
          this.isLoaderShow = true;
          this.apiServices.sendTextMessage(res['msg'], 'danger');
        }
      })
    }
    /* moveItemInArray(this.pagedItems, event.previousIndex, event.currentIndex);
    this.pagedItems.forEach((user, idx) => {
      user.order = idx + 1;
    }); */
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  resetFilters() {
    this.pageSize = 10;
    this.setPage(1, this.pageSize);
  }

  loadPlans(allow_free_user: string) {
    if (allow_free_user == "true") {
      this.allowFreeUsers = true;
    } else {
      this.allowFreeUsers = false;
      this.selectedProducts = [];
    }
  }
}


