import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { isObject } from 'util';

import { ApiServices } from '../shared/api.services';
import { PagerService } from '../services/pager.service';
import { VideoProcessingService } from '../services/video-processing-service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  page_title = "Inventory Management";
  base_url: string;
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  product_id: string;
  product_status: boolean = true;

  productForm: FormGroup;

  products;
  products_data: {} = {};
  product_list: any;
  tot_products: number;

  //Pagination Values
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;
  role: string;
  search_key: string = "";
  pageSize: number = 10;
  skip: number = 0;
  filters: any = {};

  upload_file_url: string = "";
  apiKey = '';

  isAlert = false;

  // For Prod values
  product_type: string = 'DVD';
  title: string = '';
  description: string = '';
  price: number = 0;
  /* postUrlShow = false;
  postUrl1Show = false;
  postUrl2Show = false; */


  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;


  constructor(public apiServices: ApiServices,
    private pagerService: PagerService,
    private fb: FormBuilder,
    private videoService: VideoProcessingService) {
    this.base_url = apiServices.base_url;
    this.apiKey = apiServices.tinyApiKey;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  fetchProducts(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchProducts(filters).subscribe(products => {
        resolve(products);
      })
    });
  }

  fetchProductById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.product_id = _id;
      this.filters['_id'] = this.product_id;
      this.fetchProducts(this.filters).then(products => {
        this.products_data = products['data'][0];
        this.initFrom();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.products_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  onSearch(form: NgForm) {
    this.search_key = form.value.search_user;
    this.setPage(1, this.pageSize);
  }

  // For multiple images
  imageSrc = new Array<File>();
  urls = [];
  // For Single image
  selectedFile: any;
  imgPreview: any;
  imageName: string;
  img_ext_err: Boolean = false;
  onImageSelected(event) {
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    let img_ext = (event.target.files[0].type).split("/");
    if (img_ext[0] != "image") {
      this.img_ext_err = true;
    } else {
      this.img_ext_err = false;
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        this.imageName = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = (event) => this.imgPreview = reader.result;
        // reader.readAsDataURL(file);
      }
    }
    // For multiple images
    /* let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.imageSrc.push(<File>file);
        if (file.name) {
          const reader = new FileReader();
          reader.onload = e => this.urls.push(reader.result);
          reader.readAsDataURL(file);
        }
      }
    } */
  }

  private initFrom() {
    this.apiServices.clearMessage();
    this.modelError = '';
    this.product_type = 'DVD';
    this.title = '';
    this.price = 0;
    let activeStatus = '';
    let provideDVD = 'true';
    // let showArchives = false;
    // let live_content = false;
    // let timeline_paid_content = false;
    this.description = '';
    let product_image = '';
    /* let postUrl = '';
    let postUrl1 = '';
    let postUrl2 = ''; */

    this.urls = [];
    this.imgPreview = undefined;
    this.imageName = '';
    /* this.videoName = '';
    this.videoName1 = '';
    this.videoName2 = ''; */

    // this.newsForm.reset();

    if (this.isEditable) {
      this.upload_file_url = '';
      this.product_type = this.products_data['product_type'];
      this.title = this.products_data['title'];
      this.price = this.products_data['price'];
      this.description = this.products_data['description'];
      activeStatus = `${this.products_data['status']}`;
      provideDVD = `${this.products_data['provide_dvd']}`;
      // showArchives = this.products_data['showArchives'];
      // live_content = this.products_data['live_content'];
      // timeline_paid_content = this.products_data['timeline_paid_content'];
      this.urls.push(this.base_url + this.products_data['image']);
      /* this.imageName = this.products_data['image'];
      if (this.products_data.hasOwnProperty('postUrl')) {
        this.urls.push(this.base_url + this.products_data['postUrl']['postUrl']);
        this.videoName = this.products_data['postUrl']['postUrl'];
      }
      if (this.products_data.hasOwnProperty('postUrl1')) {
        this.urls.push(this.base_url + this.products_data['postUrl1']['postUrl']);
        this.videoName1 = this.products_data['postUrl1']['postUrl'];
      }
      if (this.products_data.hasOwnProperty('postUrl2')) {
        this.urls.push(this.base_url + this.products_data['postUrl2']['postUrl']);
        this.videoName2 = this.products_data['postUrl2']['postUrl'];
      } */

      /* this.postUrlShow = this.products_data['postUrlShow'];
      this.postUrl1Show = this.products_data['postUrl1Show'];
      this.postUrl2Show = this.products_data['postUrl2Show']; */

      // news['images'].forEach(image=>{
      //   this.urls.push(this.base_url+image);
      // });
    }

    let imagesValidation = [];
    if (this.urls.length == 0) {
      imagesValidation.push(Validators.required);
    }

    /* let videoValidation = [];
    if (this.urls.length == 0) {
      videoValidation.push(Validators.required);
    }

    let videoValidation1 = [];
    if (this.urls.length == 0) {
      videoValidation1.push(Validators.required);
    }

    let videoValidation2 = [];
    if (this.urls.length == 0) {
      videoValidation2.push(Validators.required);
    } */

    this.productForm = new FormGroup({
      'product_type': new FormControl(this.product_type, Validators.required),
      'title': new FormControl(this.title, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      'price': new FormControl(this.price, [Validators.required, Validators.min(0)]),
      'description': new FormControl(this.description, Validators.required),
      'status': new FormControl(activeStatus),
      'provideDVD': new FormControl(provideDVD, Validators.required),
      // 'showArchives': new FormControl(showArchives),
      // 'live_content': new FormControl(live_content),
      // 'timeline_paid_content': new FormControl(timeline_paid_content),
      'image': new FormControl('', imagesValidation),
      /*  'postUrl': new FormControl(postUrl, videoValidation),
       'postUrl1': new FormControl(postUrl1, videoValidation1),
       'postUrl2': new FormControl(postUrl2, videoValidation2),
       'postUrlShow': new FormControl(this.postUrlShow, videoValidation),
       'postUrl1Show': new FormControl(this.postUrl1Show, videoValidation1),
       'postUrl2Show': new FormControl(this.postUrl2Show, videoValidation2) */
    });
  }

  // Define Properties for videos
  // For postUrl
  selectedFile1: any;
  postUrlThumbnail: any;
  videoName: string;
  // For postUrl1
  selectedFile2: any;
  postUrlThumbnail1: any;
  videoName1: string;
  // For postUrl2
  selectedFile3: any;
  postUrlThumbnail2: any;
  videoName2: string;

  public add(videoType: string): void {
    this.videoService.promptForVideo().then(videoFile => {
      if (videoType == "postUrl") {
        this.selectedFile1 = videoFile;
        this.videoName = videoFile.name;
      } else if (videoType == "postUrl1") {
        this.selectedFile2 = videoFile;
        this.videoName1 = videoFile.name;
      } else if (videoType == "postUrl2") {
        this.selectedFile3 = videoFile;
        this.videoName2 = videoFile.name;
      }
      // console.dir(videoFile);
      return this.videoService.generateThumbnail(videoFile);
    }).then(thumbnailData => {
      // this.imgPreview = thumbnailData;
      var img = thumbnailData;
      // Convert Base64 image to binary
      var file = this.dataURItoBlob(img);
      if (videoType == "postUrl") {
        const imageFile = new File([file], "thumbUrl.jpeg", { type: 'image/jpeg' });
        this.postUrlThumbnail = imageFile;
      } else if (videoType == "postUrl1") {
        const imageFile = new File([file], "thumbUrl1.jpeg", { type: 'image/jpeg' });
        this.postUrlThumbnail1 = imageFile;
      } else if (videoType == "postUrl2") {
        const imageFile = new File([file], "thumbUrl2.jpeg", { type: 'image/jpeg' });
        this.postUrlThumbnail2 = imageFile;
      }
    })
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  /* toggleVisibility(e, postType: string) {
    if (postType == "postUrl") {
      this.postUrlShow = e.target.checked;
    } else if (postType == "postUrl1") {
      this.postUrl1Show = e.target.checked;
    } else if (postType == "postUrl2") {
      this.postUrl2Show = e.target.checked;
    }
  } */

  onSubmit() {
    this.apiServices.cleanForm(this.productForm, 'image');
    if (this.productForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = '';
      const fd = new FormData();
      if (this.selectedFile && this.selectedFile.name)
        fd.append('image', this.selectedFile, this.selectedFile.name);
      /* if (this.selectedFile1 && this.selectedFile1.name) {
        fd.append('postUrl', this.selectedFile1, this.selectedFile1.name);
        fd.append('thumbUrl', this.postUrlThumbnail, this.postUrlThumbnail.name);
      }
      if (this.selectedFile2 && this.selectedFile2.name) {
        fd.append('postUrl1', this.selectedFile2, this.selectedFile2.name);
        fd.append('thumbUrl1', this.postUrlThumbnail1, this.postUrlThumbnail1.name);
      }
      if (this.selectedFile3 && this.selectedFile3.name) {
        fd.append('postUrl2', this.selectedFile3, this.selectedFile3.name);
        fd.append('thumbUrl2', this.postUrlThumbnail2, this.postUrlThumbnail2.name);
      } */
      fd.append('product_type', this.productForm.value.product_type);
      fd.append('title', this.productForm.value.title);
      fd.append('price', this.productForm.value.price);
      fd.append('description', this.productForm.value.description);
      fd.append('provide_dvd', this.productForm.value.provideDVD);
      fd.append('duration', '1');
      /* fd.append('postUrlShow', (this.postUrlShow).toString());
      fd.append('postUrl1Show', (this.postUrl1Show).toString());
      fd.append('postUrl2Show', (this.postUrl2Show).toString()); */

      // this.productForm.value.showArchives = (this.productForm.value.showArchives == null || this.productForm.value.showArchives == false) ? false : true;
      // this.productForm.value.live_content = (this.productForm.value.live_content == null || this.productForm.value.live_content == false) ? false : true;
      // this.productForm.value.timeline_paid_content = (this.productForm.value.timeline_paid_content == null || this.productForm.value.timeline_paid_content == false) ? false : true;
      // fd.append('showArchives', this.productForm.value.showArchives);
      // fd.append('live_content', this.productForm.value.live_content);
      // fd.append('timeline_paid_content', this.productForm.value.timeline_paid_content);

      if (this.isEditable) {
        fd.append('status', this.productForm.value.status);
        this.apiServices.editProduct(this.product_id, fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addProduct(fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.productForm.reset();
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError = "Please fill all the required fields";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.productForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
    this.selectedFile = undefined;
    this.selectedFile1 = undefined;
    this.selectedFile2 = undefined;
    this.selectedFile3 = undefined;
    this.img_ext_err = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // Checking Object or Not
    if (!isObject(this.filters)) {
      this.filters = {};
    }

    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    // set search key 
    this.filters['search_key'] = this.search_key;

    // Set news status
    this.filters['status'] = this.product_status;

    this.fetchProducts(this.filters).then(news => {
      this.product_list = news;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        this.isAlert = true;
        // total count
        this.tot_products = this.product_list['total'];
        // users list
        this.products = this.product_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_products, page, pageCount);
        // get current page of items
        this.pagedItems = this.products;
        this.pagedItemsLength = this.pagedItems.length;
      })
    delete this.filters.limit;
    delete this.filters.skip;
  }

  resetFilters() {
    this.emptyFilters();
    this.ngOnInit();
  }

  // Emptying all status variables when reset filters
  emptyFilters() {
    this.search_key = "";
    delete this.filters;
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('text copied..!');
  }

}
