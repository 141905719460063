import { Injectable } from '@angular/core';

interface Alert {
    type: string;
    message: string;
}
const ALERTS: Alert[] = [];
@Injectable()
export class ErrorDataModel {

    alerts: Alert[];

    public constructor() { }

}