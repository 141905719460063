import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from '../../shared/api.services';
import { PagerService } from 'src/app/services/pager.service';

@Component({
  selector: 'app-news-categories',
  templateUrl: './news-categories.component.html',
  styleUrls: ['./news-categories.component.css']
})

export class NewsCategoriesComponent implements OnInit {
  title = "News Categories";
  base_url;
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  news_category_id: string;

  news_categoryForm: FormGroup;

  news_categorys;
  news_category_data: {} = {};

  news_category_list: {} = {};
  news_cat_count: number = 0;


  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(public apiServices: ApiServices, private router: Router, private pagerService: PagerService) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  getNewsCategories(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getNewsCategories(filters).subscribe(news_categorys => {
        this.isLoaderShow = true;
        resolve(news_categorys);
      })
    });
  }

  getNewsCategoryById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.news_category_id = _id;
      let params = { '_id': _id };
      this.filters['_id'] = _id;
      this.apiServices.getNewsCategories(params).subscribe(news_category => {
        this.news_category_data = news_category['data'][0];
        this.initFrom();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.news_category_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.iconSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }


  private initFrom() {
    this.apiServices.clearMessage();
    this.modelError = '';
    let name = '';
    let activeStatus = 'true';

    if (this.isEditable) {
      let news_category = this.news_category_data;
      name = news_category['name'];
      activeStatus = `${news_category['status']}`;
    }
    this.news_categoryForm = new FormGroup({
      'name': new FormControl(name, Validators.required),
      'status': new FormControl(activeStatus, Validators.required)
    });
  }

  onSubmit() {
    if (this.news_categoryForm.valid == true) {
      this.modelError = '';
      if (this.isEditable) {
        this.apiServices.editNewsCategory(this.news_category_id, this.news_categoryForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        },
          error => {
            this.modelError = error;
            this.scrollTop();
          });
      } else {
        this.apiServices.addNewsCategory(this.news_categoryForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.news_categoryForm.reset();
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        },
          error => {
            this.modelError = error;
            this.scrollTop();
          });
      }
    } else {
      this.modelError = "Please select all mandatory(*) values!";
      this.scrollTop();
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }
    this.getNewsCategories(this.filters).then(news_categories => {
      this.news_category_list = news_categories;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        // total count
        this.news_cat_count = this.news_category_list['total'];
        // users list
        this.news_categorys = this.news_category_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.news_cat_count, page, pageCount);

        // get current page of items
        // this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.pagedItems = this.news_categorys;
        this.pagedItemsLength = this.pagedItems.length;
        // deleting id and search key from filters
        delete this.filters.limit; delete this.filters.skip;
      })
  }
}


