import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiServices } from '../../shared/api.services';
import { PagerService } from 'src/app/services/pager.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css']
})
export class NotificationsListComponent implements OnInit {
  title = "Notifications";
  notifications: any[];
  isReady = false;

  //Pagination variables
  pager: any = {};
  pagedItemsLength: number;
  limit: number = 10;
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;
  params = {};
  totalCount = 0;
  //End Pagination variables

  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(private apiServices: ApiServices, private pagerService: PagerService) { }

  ngOnInit() {
    this.getNotifications(1, this.pageSize);
  }

  getNotifications(page: number, pageCount: number) {
    this.isReady = false;
    if (page > 1) {
      this.params['limit'] = pageCount;
      this.params['skip'] = this.pager.currentPage * this.pager.pageSize;
    } else {
      this.params['limit'] = pageCount;
      this.params['skip'] = this.skip;
    }
    this.apiServices.getNotifications(this.params).subscribe(res => {
      if (res['status'] === 'success') {
        this.notifications = res['data'];
        this.totalCount = res['total'];;
        this.pager = this.pagerService.getPager(this.totalCount, page, pageCount);
        this.pagedItemsLength = this.notifications.length;
        delete this.params['limit']; delete this.params['skip'];
        this.apiServices.sendTextMessage(res['msg'], 'success');
        this.isReady = true;
      } else {
        this.notifications = [];
        this.apiServices.sendTextMessage(res['msg'], 'danger');
        this.isReady = true;
      }
    });
  }

}
