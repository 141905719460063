import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiServices } from '../../shared/api.services';
import { PagerService } from 'src/app/services/pager.service';

@Component({
  selector: 'app-job-categories',
  templateUrl: './job-categories.component.html',
  styleUrls: ['./job-categories.component.css']
})

export class JobCategoriesComponent implements OnInit {
  title = "Job Categories";
  base_url;
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  job_category_id: string;

  job_categoryForm: FormGroup;

  job_categorys;
  job_category_data: {} = {};

  job_category_list: {} = {};
  job_cat_count: number = 0;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};
  isAlert = false;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(public apiServices: ApiServices, private pagerService: PagerService) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  getJobCategories(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getJobCategories(filters).subscribe(job_categorys => {
        this.isLoaderShow = true;
        resolve(job_categorys);
      })
    });
  }

  getJobCategoryById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.job_category_id = _id;
      let params = { '_id': _id };
      this.filters['_id'] = this.job_category_id;
      this.apiServices.getJobCategories(params).subscribe(job_category => {
        this.job_category_data = job_category['data'][0];
        this.initFrom();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.job_category_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.iconSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }

  selectedImage: File = null;
  imageSrc: any;
  onImageSelected(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }


  private initFrom() {
    this.apiServices.clearMessage();
    this.modelError = '';
    let name = '';
    let activeStatus = 'true';

    if (this.isEditable) {
      let job_category = this.job_category_data;
      name = job_category['name'];
      activeStatus = `${job_category['status']}`;
    }
    this.job_categoryForm = new FormGroup({
      'name': new FormControl(name, Validators.required),
      'status': new FormControl(activeStatus, Validators.required)
    });
  }

  onSubmit() {
    if (this.job_categoryForm.valid == true) {
      this.modelError = '';
      if (this.isEditable) {
        this.apiServices.editJobCategory(this.job_category_id, this.job_categoryForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addJobCategory(this.job_categoryForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        });
      }
    } else {
      this.modelError = "Please select all mandatory(*) values!";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.job_categoryForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }
    // set skip value when Id is present
    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.getJobCategories(this.filters).then(job_categories => {
      this.job_category_list = job_categories;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        this.isAlert = true;
        // total count
        this.job_cat_count = this.job_category_list['total'];
        // users list
        this.job_categorys = this.job_category_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.job_cat_count, page, pageCount);
        this.pagedItems = this.job_categorys;
        this.pagedItemsLength = this.pagedItems.length;
        // deleting id and search key from filters
        delete this.filters.limit; delete this.filters.skip;
      })
  }

}


