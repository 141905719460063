import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { NgForm } from '@angular/forms';
import { ApiServices } from 'src/app/shared/api.services';

declare var tinyMCE: any;

@Component({
  selector: 'app-user-support-mail',
  templateUrl: './user-support-mail.component.html',
  styleUrls: ['./user-support-mail.component.css']
})
export class UserSupportMailComponent implements OnInit {

  title = "User Support Mail Content";
  app_mail_content: string = "";
  config_id: string = "";
  form_error: string;

  selectedFile: File = null;
  upload_file_url: string = "";
  public model = {
    app_mail_content: ''
  };

  constructor(private apiServices: ApiServices) { }

  ngOnInit() {
    this.getAppMailContent();
  }

  // public onReady(editor) {
  //   editor.ui.getEditableElement().parentElement.insertBefore(
  //     editor.ui.view.toolbar.element,
  //     editor.ui.getEditableElement()
  //   );
  // }

  onSubmit(form: NgForm) {
    var wordcount = tinyMCE.activeEditor.plugins.wordcount;
    if (wordcount.body.getWordCount() > 0) {
      let data = form.value.text_data;
      this.apiServices.setAppMailContent(data, this.config_id).subscribe(res => {
        if (res['status'] === "failure") {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(res['msg'], 'failure');
        } else {
          this.form_error = "";
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(res['msg'], 'success');
        }
      })
    } else {
      this.form_error = "content shouls not be empty";
    }

  }

  getAppMailContent() {
    this.apiServices.getAppMailContent().subscribe(res => {
      console.log(res);
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      } else {
        this.model.app_mail_content = res['data']['message'];
        this.config_id = res['data']['_id'];
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      }
    })
  }

  onFileChanged(event) {
    this.selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append('config_image', this.selectedFile, this.selectedFile.name);
    this.apiServices.uploadFile(fd).subscribe(res => {
      if (res['status'] == "failure") {
        this.apiServices.clearMessage();
      } else {
        this.apiServices.clearMessage();
        this.upload_file_url = res['data'];
        this.apiServices.sendTextMessage(this.upload_file_url, 'success');
      }
    });
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('text copied');
  }

}


