import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiServices } from '../shared/api.services';
import { PagerService } from '../services/pager.service';

@Component({
  selector: 'app-event-speakers',
  templateUrl: './event-speakers.component.html',
  styleUrls: ['./event-speakers.component.css']
})

export class EventSpeakersComponent implements OnInit {
  title = "Event Speakers";
  base_url: string = '';
  isAlert = false;
  alertClass = '';
  alertMsg = '';
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  speaker_id: string;

  speakerForm: FormGroup;

  speakers;
  speaker_data: {} = {};
  speaker_list: any;
  tot_speakers: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  role: string;
  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(public apiServices: ApiServices, private pagerService: PagerService) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  fetchSpeakers(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.fetchSpeakers(filters).subscribe(speakers => {
        this.isLoaderShow = true;
        resolve(speakers);
      })
    });
  }

  getSpeaketById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.speaker_id = _id;
      let params = {};
      params['_id'] = this.speaker_id;
      this.filters['_id'] = this.speaker_id;
      this.fetchSpeakers(params).then(language_info => {
        this.speaker_data = language_info['data'][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.speaker_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  iconSrc: any;
  selectedIcon: File = null;
  onIconSelected(event) {
    this.selectedIcon = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.iconSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }

  // For multiple images
  imageSrc = new Array<File>();
  urls = [];
  // For Single image
  selectedFile: any;
  imgPreview: any;
  imageName: string;
  onImageSelected(event) {
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imageName = file.name;
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (event) => this.imgPreview = reader.result;
      // reader.readAsDataURL(file);
    }
    // For multiple images
    /* let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.imageSrc.push(<File>file);
        if (file.name) {
          const reader = new FileReader();
          reader.onload = e => this.urls.push(reader.result);
          reader.readAsDataURL(file);
        }
      }
    } */
  }


  private initFrom() {
    this.modelError = '';
    let name = '';
    let profile_pic = '';
    let activeStatus = 'true';
    this.urls = [];
    this.imageName = '';

    if (this.isEditable) {
      let speaker_data = this.speaker_data;
      name = speaker_data['name'];
      // let profile_pic = this.speaker_data['profile_pic'];
      // this.imgPreview = this.base_url + this.speaker_data['profile_pic'];
      this.urls.push(this.base_url + this.speaker_data['profile_pic']);
      this.imageName = this.speaker_data['profile_pic'];
      activeStatus = `${speaker_data['status']}`;
    }

    let imagesValidation = [];
    if (this.urls.length == 0) {
      imagesValidation.push(Validators.required);
    }

    this.speakerForm = new FormGroup({
      'name': new FormControl(name, Validators.required),
      'profile_pic': new FormControl(profile_pic, imagesValidation),
      'status': new FormControl(activeStatus, Validators.required)
    });
  }

  onSubmit() {
    if (this.speakerForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = '';
      const fd = new FormData();
      if (this.selectedFile && this.selectedFile.name)
        fd.append('profile_pic', this.selectedFile, this.selectedFile.name);
      fd.append('name', this.speakerForm.value.name);
      fd.append('status', this.speakerForm.value.status);
      if (this.isEditable) {
        this.apiServices.editSpeaker(this.speaker_id, fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.imgPreview = undefined;
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
            // this.successAlert(res['msg']);
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            // this.errorAlert(res['msg']);
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
          }
        },
          error => {
            this.errorAlert(error);
          });
      } else {
        this.apiServices.addSpeaker(fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.speakerForm.reset();
            this.imgPreview = undefined;
            this.setPage(this.pager.currentPage, this.pageSize);
            // this.successAlert(res['msg']);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            // this.errorAlert(res['msg']);
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
          }
        },
          error => {
            this.errorAlert(error);
          });
      }
    } else {
      this.modelError = "Please select all mandatory(*) values!";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-success';
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-danger';
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.fetchSpeakers(this.filters).then(speakersData => {
      this.speaker_list = speakersData;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        // total count
        this.tot_speakers = this.speaker_list['total'];
        // users list
        this.speakers = this.speaker_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_speakers, page, pageCount);
        // get current page of items
        this.pagedItems = this.speakers;
        this.pagedItemsLength = this.pagedItems.length;
      })
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

}