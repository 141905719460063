import { Component, OnInit, Inject } from '@angular/core';
import { ApiServices } from '../shared/api.services';
import { PagerService } from '../services/pager.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ReportPopup {
  username: string;
  reports: [{}];
}

@Component({
  selector: 'app-abused-reports',
  templateUrl: './abused-reports.component.html',
  styleUrls: ['./abused-reports.component.css']
})
export class AbusedReportsComponent implements OnInit {

  title = "Abused Reports";
  abused_users: any;
  abused_user_data = {};
  abused_user_id: string;
  abused_user_status: string = "";
  abused_users_list: any;
  abused_users_count: number;
  successMsg: string;
  errorMsg: string;
  modelError = '';

  isAlert = false;
  alertClass = '';
  alertMsg = '';

  isLoaderShow = false;

  constructor(public apiServices: ApiServices,
    private pagerService: PagerService,
    public dialog: MatDialog) { }

  base_url: string;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  pagedItemsLength: number;
  // Set number of records per page
  limit: number = 10;
  // Pagination elements
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};
  is_admin_reporting = "false";

  ngOnInit() {
    this.base_url = this.apiServices.base_url;
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  changeUserLimit(event: any) {
    this.pageSize = parseInt(event.target.value);
    this.setPage(1, this.pageSize);
  }

  getKeys(obj) {
    return Object.keys(obj)
  }

  getAbusedReports(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.getAbusedReports(filters).subscribe(abused_users => {
        this.isLoaderShow = true;
        resolve(abused_users);
      })
    })
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }
    // set skip value when Id is present
    if (this.abused_user_status == "true") {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
    }
    /* if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    } */

    // set filters
    this.filters['is_admin_reporting'] = this.is_admin_reporting;

    this.getAbusedReports(this.filters).then(userdata => {
      this.abused_users_list = userdata;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        // total count
        this.abused_users_count = this.abused_users_list['total'];
        // users list
        this.abused_users = this.abused_users_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.abused_users_count, page, pageCount);

        // get current page of items
        // this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.pagedItems = this.abused_users;
        this.pagedItemsLength = this.pagedItems.length;
        // deleting id and search key from filters
        delete this.filters.limit; delete this.filters.skip;
      })
  }

  blockUser(user_id: string) {
    var con_string = document.getElementById("block").innerHTML;
    if (confirm(con_string)) {
      this.abused_user_status = "true";
      let params = {
        userId: user_id,
        disable: this.abused_user_status
      }
      // Block abused user
      this.apiServices.changeUserStatus(params).subscribe(res => {
        if (res['status'] == "success") {
          // status updated
        } else {
          // status not updated
        }
        this.setPage(1, this.pageSize);
      });
    }
  }

  openReportDialog(username: string, reports: [{}]): void {
    const dialogRef = this.dialog.open(ReportsOverView, {
      width: '1000px',
      data: { username: username, reports: reports }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

@Component({
  selector: 'reports-popup',
  templateUrl: 'abused-reports-popup.html',
})
export class ReportsOverView {

  constructor(
    public dialogRef: MatDialogRef<ReportsOverView>,
    @Inject(MAT_DIALOG_DATA) public data: ReportPopup) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
