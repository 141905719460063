import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoProcessingService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    public promptForVideo(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            // make file input element in memory
            const fileInput: HTMLInputElement = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'video/*';
            fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            }, { passive: false });
            fileInput.addEventListener('change', event => {
                resolve(fileInput.files[0]);
            }, { passive: false });
            // prompt for video file
            fileInput.click();
        });
    }

    public generateThumbnail(videoFile: Blob): Promise<String> {
        const video: HTMLVideoElement = this.document.createElement('video');
        const canvas: HTMLCanvasElement = this.document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        return new Promise<String>((resolve, reject) => {
            canvas.addEventListener('error', reject, { passive: false });
            video.addEventListener('error', reject, { passive: false });
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL())
            }, { passive: false });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            video.load();
        });
    }
}