import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ErrorDataModel } from '../shared/message/error.data.model';

import { AppConstants } from '../constants';
import { FormGroup } from '@angular/forms';

declare var tinyMCE: any;


@Injectable()
export class ApiServices implements OnInit {
    private loggedIn = new BehaviorSubject<boolean>(this.tokenAvailable());
    token = null;
    headers;
    base_url: string;
    ApiVersion: string;
    tinyApiKey = AppConstants.tinyApiKey;
    tinyInit = AppConstants.tinyInit;

    constructor(private http: HttpClient, private router: Router, private errorData: ErrorDataModel) {
        this.base_url = AppConstants.testURL;
        this.ApiVersion = AppConstants.apiVersion;
    }

    ngOnInit() { }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    private tokenAvailable(): boolean {
        return !!localStorage.getItem('token');
    }

    public cleanForm(formGroup: FormGroup, image_key: string = "", editor_id: string = "") {
        // Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
        Object.keys(formGroup.controls).forEach((key) => {
            if (typeof (formGroup.get(key).value) == "string" && key !== image_key) {
                formGroup.get(key).setValue(formGroup.get(key).value.trim())
            }
        })
        /* if (editor_id != "") {
            let editorContent = tinyMCE.get(editor_id).getContent({ format: 'text' }).trim();
            tinyMCE.get(editor_id).setContent(editorContent);
            formGroup.get(editor_id).setValue(editorContent);
        } */
    }

    signinUser(email: string, password: string) {
        const params = {
            email: email,
            password: password
        };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'platform': 'web' });
        return this.http.post(this.base_url + this.ApiVersion + 'users/auth', params, { headers: headers });
    }

    saveAuthToken(data: Array<any>) {
        return new Promise((resolve, reject) => {
            localStorage.setItem('token', data['token']);
            localStorage.setItem('role', data['role']);
            localStorage.setItem('notify_tokens', JSON.stringify(data['notify_tokens']));
            localStorage.setItem('lang', 'en');

            this.token = data['token'];
            resolve(this.token);
        })
    }

    getAuthToken() {
        return localStorage.getItem('token');
    }

    public localStorageItem(id: string): string {
        return localStorage.getItem(id);
    }

    createAuthenticationHeaders() {
        const headerSettings: { [name: string]: string | string[]; } = {};
        let headers: HttpHeaders = new HttpHeaders();
        if (this.token == null) {
            this.token = this.getAuthToken();
        }
        headerSettings['Authorization'] = 'Bearer ' + this.token;
        headerSettings['platform'] = 'web';
        this.headers = {
            headers: new HttpHeaders(headerSettings)
        };
    }

    createPostAuthenticationHeaders() {
        const headerSettings: { [name: string]: string | string[]; } = {};
        let headers: HttpHeaders = new HttpHeaders();
        if (this.token == null) {
            this.token = this.getAuthToken();
        }
        headerSettings['Authorization'] = 'Bearer ' + this.token;
        headerSettings['platform'] = 'web';
        this.headers = new HttpHeaders(headerSettings)
    }

    getUserProfile() {
        return new Promise((resolve, reject) => {
            this.createAuthenticationHeaders();
            this.http.get(this.base_url + this.ApiVersion + 'users/me', this.headers)
                .subscribe(profile => {
                    if (profile['status'] === 'failure') {
                        this.sendTextMessage(profile['msg'], 'danger');
                    } else {
                        this.sendTextMessage(profile['msg'], 'success');
                        localStorage.setItem('session', JSON.stringify(profile['data']));
                        this.loggedIn.next(true);
                        resolve(profile['data']);
                    }
                });
        })
    }

    onLogOut(msg: string, type: string) {
        this.loggedIn.next(false);
        localStorage.clear();
        this.errorData.alerts = [{
            'type': type,
            'message': msg,
        }];
        this.router.navigate(['/signin']);
    }

    isAuthenticated() {
        this.token = this.getAuthToken();
        return this.token != null;
    }

    getUsers(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] != undefined && filters[key] != null) {
                if (key == 'dateTimeRange') {
                    if (filters[key][0] != null && filters[key][1] != null)
                        params[key] = filters[key];
                } else {
                    params[key] = filters[key];
                }
            }
        });
        return this.http.post(this.base_url + this.ApiVersion + 'users/list', params, this.headers);
    }

    getCountries() {
        return this.http.get(this.base_url + this.ApiVersion + 'countries', this.headers);
    }

    getLocations(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters.country_id != "" && filters.country_id != null) {
            params['country_id'] = filters.country_id;
        }
        if (filters._id != null && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.search_key != null && filters.search_key != "") {
            params['name'] = filters.search_key;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'locations/search', params, this.headers);
    }

    addLocation(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'locations', params, this.headers);
    }

    editLocation(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'locations/' + _id, params, this.headers);
    }

    updateAdminProfile(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/profile', params, this.headers);
    }

    getLanguages(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'languages/search', params, this.headers);
    }

    addLanguage(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'languages', params, this.headers);
    }

    editLanguage(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'languages/' + _id, params, this.headers);
    }

    getJobCategories(filters: any) {
        let params = {};
        this.createAuthenticationHeaders();
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'jobCategories/search', params, this.headers);
    }

    addJobCategory(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'jobCategories', params, this.headers);
    }

    editJobCategory(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'jobCategories/' + _id, params, this.headers);
    }

    getJobs(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters.country_id != "" && filters.country_id != null) {
            params['country_id'] = filters.country_id;
        }
        if (filters.location_code != "" && filters.location_code != null) {
            params['location'] = filters.location_code;
        }
        if (filters.language_code != "" && filters.language_code != null) {
            params['language'] = filters.language_code;
        }
        if (filters.category_code != "" && filters.category_code != null) {
            params['category'] = filters.category_code;
        }
        if (filters.job_type != "" && filters.job_type != null) {
            params['job_type'] = filters.job_type;
        }
        params['job_status'] = filters.job_status;
        if (filters.title != null && filters.title != "") {
            params['title'] = filters.title;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'jobs/search', params, this.headers);
    }

    getJobDetails(_id: string) {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'jobs/' + _id, this.headers);
    }

    addJob(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'jobs', params, this.headers);
    }

    editJob(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'jobs/' + _id, params, this.headers);
    }

    getNewsCategories(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'newsCategories/search', params, this.headers);
    }

    addNewsCategory(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'newsCategories', params, this.headers);
    }

    editNewsCategory(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'newsCategories/' + _id, params, this.headers);
    }

    getNews(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        /* if (filters.country_id != "" && filters.country_id != null) {
            params['country_id'] = filters.country_id;
        } */
        if (filters._id != null && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.search_key != null && filters.search_key != "") {
            params['title'] = filters.search_key;
        }
        /*  if (filters.language_code != "" && filters.language_code != null) {
             params['language'] = filters.language_code;
         } */
        /* if (filters.category_code != "" && filters.category_code != null) {
            params['category'] = filters.category_code;
        } */
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        if (filters.status != null) {
            params['status'] = filters.status;
        }
        if (filters.status != null) {
            params['status'] = filters.status;
        }
        if (filters.subscription_type != null) {
            params['subscription_type'] = filters.subscription_type;
        }
        if (filters.product_ids != null) {
            params['product_ids'] = filters.product_ids;
        }

        return this.http.post(this.base_url + this.ApiVersion + 'news/all', params, this.headers);
    }

    addNews(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'news', params, this.headers);
    }

    editNews(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'news/' + _id, params, this.headers);
    }

    getKycUsers() {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'kyc/list', this.headers);
    }

    editUser(_id: string, is_active: string) {
        let params = {};
        params['is_active'] = is_active;
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'users/profile/' + _id, params, this.headers);
    }

    private subject = new Subject<any>();

    sendTextMessage(message: string, type: string) {
        this.subject.next({ message: message, type: type });
    }

    clearMessage() {
        this.subject.next('reset');
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    changeKycStatus(id: string, status: string) {
        let params = {};
        params['_id'] = id;
        params['is_kyc_verified'] = status;
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/changeKycStatus', params, this.headers);
    }

    getCurrencies() {
        return this.http.post(this.base_url + this.ApiVersion + 'currencies/search', {}, this.headers);
    }

    changePassword(oldPwd: string, newPwd: string) {
        this.createAuthenticationHeaders();
        let params = {};
        params['oldPassword'] = oldPwd;
        params['newPassword'] = newPwd;
        return this.http.put(this.base_url + this.ApiVersion + 'users/password', params, this.headers);
    }

    getFaqs(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'faqs/search', params, this.headers);
    }

    addFaqs(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'faqs', params, this.headers);
    }

    addMember(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/addMember', params, this.headers);
    }
    editFaqs(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'faqs/' + _id, params, this.headers);
    }

    resetPassword(email: string) {
        const params = {
            email: email,
        };
        return this.http.post(this.base_url + this.ApiVersion + 'users/forgotpassword', params);
    }

    validateKey(key: string) {
        return this.http.get(this.base_url + this.ApiVersion + 'users/validateKey/' + key);
    }

    setNewPassword(key: string, password: string) {
        let params = {};
        params['key'] = key;
        params['password'] = password;
        return this.http.put(this.base_url + this.ApiVersion + 'users/setPassword', params);
    }

    getChatHeads(params: {}) {
        var url = this.ApiVersion + 'support_chats/support/search';
        if (localStorage.getItem('role') === "admin") {
            url = this.ApiVersion + 'support_chats/admin/search';
        }
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + url, params, this.headers);
    }

    getChat(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'support_chats/support/search', params, this.headers);
    }

    sendMessage(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'support_chats/support', params, this.headers);
    }

    getBotChatHeads(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'bot_chats/support/search', params, this.headers);
    }

    getBotChat(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'bot_chats/support/search', params, this.headers);
    }

    sendBotMessage(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'bot_chats/support', params, this.headers);
    }

    changeChatSTatus(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'support_chats/support/status', params, this.headers);
    }

    changeBotChatSTatus(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'bot_chats/support/status', params, this.headers);
    }

    getConfigurations() {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'configurations', this.headers);
    }

    setPrivacyPolicy(data, _id) {
        let params = {};
        params['privacy_policy'] = data;
        this.createAuthenticationHeaders();
        if (_id !== "") {
            return this.http.put(this.base_url + this.ApiVersion + 'configurations/' + _id, params, this.headers);
        } else {
            return this.http.post(this.base_url + this.ApiVersion + 'configurations', params, this.headers);
        }
    }

    setTermsConditions(data, _id) {
        let params = {};
        params['terms_conditions'] = data;
        this.createAuthenticationHeaders();
        if (_id !== "") {
            return this.http.put(this.base_url + this.ApiVersion + 'configurations/' + _id, params, this.headers);
        } else {
            return this.http.post(this.base_url + this.ApiVersion + 'configurations', params, this.headers);
        }
    }

    setAboutCompany(data, _id) {
        let params = {};
        params['about_company'] = data;
        this.createAuthenticationHeaders();
        if (_id !== "") {
            return this.http.put(this.base_url + this.ApiVersion + 'configurations/' + _id, params, this.headers);
        } else {
            return this.http.post(this.base_url + this.ApiVersion + 'configurations', params, this.headers);
        }
    }
    getGeneralChat(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'general_chats/search/support', params, this.headers);
    }

    sendGeneralChatConversation(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'general_chats/support', params, this.headers);
    }

    getPrefecture(pincode: any) {
        let one = pincode.substring(0, 3);
        let two = pincode.substring(3, 7);
        let url = ' https://madefor.github.io/postal-code-api/api/v1/' + one + '/' + two + '.json';
        return this.http.get(url);
    }

    editMember(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'users/profile/' + _id, params, this.headers);
    }

    userUpload(user_data: Array<Object>) {
        this.createAuthenticationHeaders();
        let params = {}
        params['userlist'] = user_data;
        return this.http.post(this.base_url + this.ApiVersion + 'users/expressUpload', params, this.headers);
    }

    saveFirebaseToken(token) {
        this.createAuthenticationHeaders();
        let params = {}
        params['notify_token'] = token;
        return this.http.post(this.base_url + this.ApiVersion + 'notifications/saveToken', params, this.headers);
    }

    sendNotification(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'notifications/sendNotification', params, this.headers);
    }

    getNotifications(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'notifications/search', params, this.headers);
    }

    getSentNotifications(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'notifications/adminNotifications', params, this.headers);
    }

    getAllusers(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/expressDownload', params, this.headers);
    }

    public blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }

    getStats(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/dashboard', params, this.headers);
    }

    uploadFile(params: FormData) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'configurations/image', params, this.headers);
    }

    getEvents(params: any) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'events/search', params, this.headers);
    }

    addEvent(params: any) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'events', params, this.headers);
    }

    editEvent(_id, params: any) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'events/' + _id, params, this.headers);
    }

    dragEvent(_id, params: any) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'events/drag/' + _id, params, this.headers);
    }

    deleteEvent(_id) {
        this.createAuthenticationHeaders();
        return this.http.delete(this.base_url + this.ApiVersion + 'events/' + _id, this.headers);
    }

    getQuestions(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'questionnaire/search', params, this.headers);
    }

    addQuestion(params) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'questionnaire', params, this.headers);
    }

    updateQuestion(id, params) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'questionnaire/' + id, params, this.headers);
    }

    getSurveyReport() {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'survey/report', {}, this.headers);
    }

    getSpeakers() {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'speakers/search', {}, this.headers);
    }

    getParticipants() {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/all', {}, this.headers);
    }

    getAbusedReports(filters: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/abusedReports', filters, this.headers);
    }

    getRequestReports(filters: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/requestReports', filters, this.headers);
    }

    changeUserStatus(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'users/disable', params, this.headers);
    }

    fetchPosts(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] != undefined && filters[key] != null) {
                if (key == 'dateTimeRange') {
                    if (filters[key][0] != null && filters[key][1] != null)
                        params[key] = filters[key];
                } else {
                    params[key] = filters[key];
                }
            }
        });
        return this.http.post(this.base_url + this.ApiVersion + 'posts', params, this.headers);
    }

    createPost(params: {}): Observable<any> {
        this.createPostAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'posts/create', params, {
            headers: this.headers,
            reportProgress: true,
            observe: 'events'
        });
    }

    editPost(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'posts/' + _id, params, this.headers);
    }

    fetchProducts(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        if (filters.status && filters.status != "") {
            params['status'] = filters.status;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'products/', params, this.headers);
    }

    addProduct(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'products/create', params, this.headers);
    }

    editProduct(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'products/' + _id, params, this.headers);
    }

    fetchSubscrHistory(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] != undefined && filters[key] != null) {
                if (key == 'dateTimeRange') {
                    if (filters[key][0] != null && filters[key][1] != null)
                        params[key] = filters[key];
                } else {
                    params[key] = filters[key];
                }
            }
        });
        return this.http.post(this.base_url + this.ApiVersion + 'payments/list', params, this.headers);
    }

    toggleAdminAccess(_id: string, permitted_live_video: boolean) {
        let params = {};
        params['permitted_live_video'] = permitted_live_video;
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'users/profile/' + _id, params, this.headers);
    }

    getCategories(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'postCategories/search', params, this.headers);
    }

    addCategory(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'postCategories/create', params, this.headers);
    }

    editCategory(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'postCategories/' + _id, params, this.headers);
    }

    fetchTimelineCtaegories(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'postCategories/search', params, this.headers);
    }

    fetchPrefectures(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'delivaryAddress', params, this.headers);
    }

    addPrefecture(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'delivaryAddress/create', params, this.headers);
    }

    editPrefecture(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'delivaryAddress/' + _id, params, this.headers);
    }

    fetchSpeakers(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'speakers/search', params, this.headers);
    }

    addSpeaker(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'speakers', params, this.headers);
    }

    editSpeaker(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'speakers/' + _id, params, this.headers);
    }

    /* fetchMeetings(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'meetings/search', params, this.headers);
    }

    addMeeting(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'meetings', params, this.headers);
    }

    editMeeting(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'meetings/' + _id, params, this.headers);
    } */

    getMeetings(params: any) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'meetings/search', params, this.headers);
    }

    addMeeting(params: any) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'meetings', params, this.headers);
    }

    editMeeting(_id, params: any) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'meetings/' + _id, params, this.headers);
    }

    dragMeeting(_id, params: any) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'meetings/drag/' + _id, params, this.headers);
    }

    deleteMeeting(_id) {
        this.createAuthenticationHeaders();
        return this.http.delete(this.base_url + this.ApiVersion + 'meetings/' + _id, this.headers);
    }

    fetchConfigurations() {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'configurations/', this.headers);
    }

    updateConfigurations(id: string, params: any) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'configurations/' + id, params, this.headers);
    }

    setCancelRefundPolicy(data: any, id: string) {
        let params = {};
        params['message'] = data;
        this.createAuthenticationHeaders();
        if (id !== "") {
            return this.http.put(this.base_url + this.ApiVersion + 'users/cancelPolicy/' + id, params, this.headers);
        } else {
            return this.http.post(this.base_url + this.ApiVersion + 'users/cancelPolicy', params, this.headers);
        }
    }

    getCancelRefundPolicy() {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'users/getCancelPolicy', this.headers);
    }

    exportSubscrHistory(filters: {}) {
        this.createAuthenticationHeaders();
        let params = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] != undefined && filters[key] != null) {
                if (key == 'dateTimeRange') {
                    if (filters[key][0] != null && filters[key][1] != null)
                        params[key] = filters[key];
                } else {
                    params[key] = filters[key];
                }
            }
        });
        return this.http.post(this.base_url + this.ApiVersion + 'payments/excelReport', params, this.headers);
    }

    setAppMailContent(data: any, id: string) {
        let params = {};
        params['message'] = data;
        this.createAuthenticationHeaders();
        if (id !== "") {
            return this.http.put(this.base_url + this.ApiVersion + 'users/appMailContent/' + id, params, this.headers);
        } else {
            return this.http.post(this.base_url + this.ApiVersion + 'users/appMailContent', params, this.headers);
        }
    }

    getAppMailContent() {
        this.createAuthenticationHeaders();
        return this.http.get(this.base_url + this.ApiVersion + 'users/getAppMailContent', this.headers);
    }

    addAnnouncementVideo(params: {}): Observable<any> {
        this.createPostAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'posts/timelinevideo', params, {
            headers: this.headers,
            reportProgress: true,
            observe: 'events'
        });
    }

    updateAnnouncementVideo(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'posts/updateStatus', params, this.headers);
    }

    fetchAnnounceVideos(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] != undefined && filters[key] != null) {
                if (key == 'dateTimeRange') {
                    if (filters[key][0] != null && filters[key][1] != null)
                        params[key] = filters[key];
                } else {
                    params[key] = filters[key];
                }
            }
        });
        return this.http.post(this.base_url + this.ApiVersion + 'posts/announcementsList', params, this.headers);
    }

    _fetchFeaturesList(filters: any) {
        this.createAuthenticationHeaders();
        let params = {};
        if (filters._id && filters._id != "") {
            params['_id'] = filters._id;
        }
        if (filters.skip != null && filters.limit != null) {
            params['skip'] = filters.skip;
            params['limit'] = filters.limit;
        }
        return this.http.post(this.base_url + this.ApiVersion + 'features/list', params, this.headers);
    }

    _addFeature(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'features/add', params, this.headers);
    }

    _editFeature(_id: string, params: {}) {
        this.createAuthenticationHeaders();
        return this.http.put(this.base_url + this.ApiVersion + 'features/' + _id, params, this.headers);
    }

    _timelineCategorySwap(params: {}) {
        this.createAuthenticationHeaders();
        return this.http.post(this.base_url + this.ApiVersion + 'postCategories/swap', params, this.headers);
    }
}
