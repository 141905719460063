import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { NgForm } from '@angular/forms';
import { ApiServices } from 'src/app/shared/api.services';

@Component({
  selector: 'app-cancel-refund',
  templateUrl: './cancel-refund.component.html',
  styleUrls: ['./cancel-refund.component.css']
})
export class CancelRefundComponent implements OnInit {

  title = "Cancel/Refund Policy";
  cancel_refund: string = "";
  config_id: string = "";

  selectedFile: File = null;
  upload_file_url: string = "";
  public model = {
    cancel_refund: ''
  };

  constructor(private apiServices: ApiServices) { }

  ngOnInit() {
    this.getCancelRefundPolicy();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  onSubmit(form: NgForm) {
    let data = form.value.text_data;
    this.apiServices.setCancelRefundPolicy(data, this.config_id).subscribe(res => {
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      } else {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      }
    })
  }

  getCancelRefundPolicy() {
    this.apiServices.getCancelRefundPolicy().subscribe(res => {
      console.log(res);
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      } else {
        this.model.cancel_refund = res['data']['message'];
        this.config_id = res['data']['_id'];
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      }
    })
  }

  onFileChanged(event) {
    this.selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append('config_image', this.selectedFile, this.selectedFile.name);
    this.apiServices.uploadFile(fd).subscribe(res => {
      if (res['status'] == "failure") {
        this.apiServices.clearMessage();
      } else {
        this.apiServices.clearMessage();
        this.upload_file_url = res['data'];
        this.apiServices.sendTextMessage(this.upload_file_url, 'success');
      }
    });
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('copyed text');
  }

}

