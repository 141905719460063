import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { compareValidator } from 'src/app/shared/compare-validator.directive';
import { ApiServices } from '../../shared/api.services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePwdForm: FormGroup;
  title = "Change Password";
  res: any;
  constructor(private fb: FormBuilder, private apiServices: ApiServices) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    let oldPassword = "";
    let newPpassword = "";
    let cnfPpassword = "";

    this.changePwdForm = new FormGroup({
      'old_password': new FormControl(oldPassword, Validators.required),
      'new_password': new FormControl(newPpassword, Validators.required),
      'cnf_password': new FormControl(cnfPpassword, [Validators.required, Validators.minLength(8)])
    });

    // this.changePwdForm = this.fb.group({
    //   password: ['', Validators.required],
    //   cnf_password: ['', [Validators.required, compareValidator('new_password')]],
    // })
  }
  
  get password() {
    return this.changePwdForm.get('password');
  }
  get pwConfirm() {
    return this.changePwdForm.get('cnf_password');
  }

  onSubmit() {
    const old_pwd = this.changePwdForm.value.old_password;
    const new_pwd = this.changePwdForm.value.new_password;
    if (this.checkPasswords()) {
      this.apiServices.changePassword(old_pwd, new_pwd).subscribe(result => {
        this.res = result;
        if (this.res['status'] === "failure") {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(this.res['msg'], 'danger');
        } else {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(this.res['msg'], 'success');
          this.changePwdForm.reset();
        }
      })
    } else {
      this.apiServices.clearMessage();
      this.apiServices.sendTextMessage("Confirm password mismatch", 'danger');
    }
  }

  checkPasswords() { // here we have the 'passwords' group
    let pass = this.changePwdForm.value.new_password;
    let confirmPass = this.changePwdForm.value.cnf_password;

    return pass === confirmPass ? true : false;
  }
}
