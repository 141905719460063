import { UserDetailsComponent } from './users/user-details/user-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth-guard.service';
import { UsersComponent } from './users/users.component';
import { ProfileComponent } from './users/profile/profile.component';
import { LocationsComponent } from './locations/locations.component';
import { LanguagesComponent } from './languages/languages.component';
import { JobsComponent } from './jobs/jobs/jobs.component';
import { KycComponent } from './kyc/kyc/kyc.component';
import { JobCategoriesComponent } from './jobs/job-categories/job-categories.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { NewsComponent } from './news/news/news.component';
import { NewsCategoriesComponent } from './news/news-categories/news-categories.component';
import { KycDetailsComponent } from '../app/kyc/kyc-details/kyc-details.component';
import { ChatComponent } from './chat/chat/chat-head/chat.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ChatDefaultComponent } from './chat/chat-default/chat-default.component';
import { ChatChildComponent } from './chat/chat/chat-child/chat-child.component';
import { PrivacyPolicyComponent } from './privacy/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './privacy/terms-conditions/terms-conditions.component';
import { AboutComponent } from './privacy/about/about.component';
import { BotHeadComponent } from './chat/bot-chat/bot-head/bot-head.component';
import { BotChildComponent } from './chat/bot-chat/bot-child/bot-child.component';
import { UploadUsersComponent } from './users/upload-users/upload-users.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsListComponent } from './notifications/notifications-list/notifications-list.component';
import { FullCalendarTestComponent } from './full-calendar-test/full-calendar-test.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { AbusedReportsComponent } from './abused-reports/abused-reports.component';
import { RequestedReportsComponent } from './requested-reports/requested-reports.component';
import { TimeLineListComponent } from './time-line-list/time-line-list.component';
import { InventoryComponent } from './inventory/inventory.component';
import { SubscriptionHistoryComponent } from './subscription-history/subscription-history.component';
import { TimelineCategoriesComponent } from './timeline-categories/timeline-categories.component';
import { DeliveryPrefecturesComponent } from './delivery-prefectures/delivery-prefectures.component';
import { EventSpeakersComponent } from './event-speakers/event-speakers.component';
// import { MeetingsComponent } from './meetings/meetings.component';
import { SpeakerMeetingsComponent } from './speaker-meetings/speaker-meetings.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { CancelRefundComponent } from './cancel-refund/cancel-refund.component';
import { ZformComponent } from './zform/zform.component';
import { UserSupportMailComponent } from './settings/user-support-mail/user-support-mail.component';
import { AnnouncementVideosComponent } from './announcement-videos/announcement-videos.component';
import { FeatureToggleComponent } from './feature-toggle/feature-toggle.component';
// import { UploadsComponent } from './uploads/uploads.component';



const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'locations', component: LocationsComponent, canActivate: [AuthGuard] },
  { path: 'languages', component: LanguagesComponent, canActivate: [AuthGuard] },
  { path: 'jobs', component: JobsComponent, canActivate: [AuthGuard] },
  { path: 'jobCategories', component: JobCategoriesComponent, canActivate: [AuthGuard] },
  // { path: 'news', component: NewsComponent, canActivate: [AuthGuard] },
  { path: 'newsCategories', component: NewsCategoriesComponent, canActivate: [AuthGuard] },
  { path: 'kyc', component: KycComponent, canActivate: [AuthGuard] },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
  {
    path: 'chat', component: ChatComponent, children: [
      { path: '', component: ChatDefaultComponent },
      { path: ':uniq_id', component: ChatChildComponent }]
  }, {
    path: 'bot_chat', component: BotHeadComponent, children: [
      { path: '', component: ChatDefaultComponent },
      { path: ':uniq_id', component: BotChildComponent }]
  },
  { path: 'kyc-details', component: KycDetailsComponent, canActivate: [AuthGuard] },
  { path: 'faqs', component: FaqsComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:key', component: ResetPasswordComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
  { path: 'user-upload', component: UploadUsersComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'notifications-list', component: NotificationsListComponent, canActivate: [AuthGuard] },
  { path: 'users/:uid', component: UserDetailsComponent, canActivate: [AuthGuard] },
  // { path: 'full-calendar', component: FullCalendarTestComponent, canActivate: [AuthGuard] },
  { path: 'questionnaire', component: QuestionnaireComponent, canActivate: [AuthGuard] },
  { path: 'abused-reports', component: AbusedReportsComponent, canActivate: [AuthGuard] },
  { path: 'requested-reports', component: RequestedReportsComponent, canActivate: [AuthGuard] },
  { path: 'time-line', component: TimeLineListComponent, canActivate: [AuthGuard] },
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
  { path: 'subscription-history', component: SubscriptionHistoryComponent, canActivate: [AuthGuard] },
  { path: 'timeline-categories', component: TimelineCategoriesComponent, canActivate: [AuthGuard] },
  { path: 'delivery-prefectures', component: DeliveryPrefecturesComponent, canActivate: [AuthGuard] },
  { path: 'admin/event-speakers', component: EventSpeakersComponent, canActivate: [AuthGuard] },
  { path: 'admin/events', component: FullCalendarTestComponent, canActivate: [AuthGuard] },
  // { path: 'admin/meetings', component: MeetingsComponent, canActivate: [AuthGuard] },
  { path: 'admin/meetings', component: SpeakerMeetingsComponent, canActivate: [AuthGuard] },
  { path: 'admin/configurations', component: ConfigurationsComponent, canActivate: [AuthGuard] },
  { path: 'admin/settings/cancelRefund', component: CancelRefundComponent, canActivate: [AuthGuard] },
  { path: 'admin/settings/app-mail-content', component: UserSupportMailComponent, canActivate: [AuthGuard] },
  { path: 'admin/zform', component: ZformComponent, canActivate: [AuthGuard] },
  { path: 'announcement-videos', component: AnnouncementVideosComponent, canActivate: [AuthGuard] },
  { path: 'admin/bulletin', component: NewsComponent, canActivate: [AuthGuard] },
  { path: 'admin/feature-list', component: FeatureToggleComponent, canActivate: [AuthGuard] },
  // { path: 'admin/file-upload', component: UploadsComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
