import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'; // ChangeDetectionStrategy
import { Location } from "@angular/common";
import { OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
/* import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list'; */
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { ApiServices } from '../shared/api.services';

export interface DialogData {
  _id: string;
  date: string;
  meeting_data: any;
}

@Component({
  selector: 'app-speaker-meetings',
  templateUrl: './speaker-meetings.component.html',
  styleUrls: ['./speaker-meetings.component.scss']
})

export class SpeakerMeetingsComponent implements OnInit {

  title = 'Speaker Mettings';
  options: OptionsInput;
  eventsModel: any;
  modelError = '';

  meetings_data: any;
  total_meetings = 0;
  meeting_data: {} = {};
  curr_month = new Date().getMonth() + 1;
  curr_year = new Date().getFullYear();

  base_url = '';

  @ViewChild('fullcalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(private apiServices: ApiServices,
    public dialog: MatDialog) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.getMeetings();
    this.options = {
      editable: true,
      timeZone: 'Asia/Tokyo', // the default = "local" (unnecessary to specify)
      customButtons: {
        myCustomButton: {
          text: 'custom!',
          click: function () {
            alert('clicked the custom button!');
          }
        }
      },
      header: {
        left: '', // prev,next today myCustomButton
        center: 'title',
        right: 'dayGridMonth', // timeGridWeek,timeGridDay,listWeek
      },
      plugins: [dayGridPlugin, interactionPlugin], // timeGridPlugin, listPlugin
      droppable: true,
      eventLimit: true,
      views: {
        month: {
          eventLimit: 3
        }
      },
    };
  }

  updateHeader() {
    this.options.header = {
      left: 'prev,next myCustomButton',
      center: 'title',
      right: ''
    };
  }

  updateEvents() {
    this.eventsModel = [{
      title: 'Update Event',
      start: this.yearMonth + '-08',
      end: this.yearMonth + '-10'
    }];
  }

  get yearMonth(): string {
    const dateObj = new Date();
    return dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
  }

  dateClick(model): void {
    const dialogRef = this.dialog.open(DialogForAddMeeting, {
      // width: '80%',
      data: { date: model.dateStr },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getMeetings();
    });
  }

  meetingClick(model) {
    let params = {
      "_id": model.event.id,
      "month": this.curr_month,
      "year": this.curr_year
    }
    this.apiServices.getMeetings(params).subscribe(res => {
      if (res['status'] == "success") {
        this.meeting_data = res['data'];
        const dialogRef = this.dialog.open(DialogForAddMeeting, {
          // width: '100%',
          data: { meeting_data: this.meeting_data[0] }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.getMeetings();
        });
      }
    });

  }

  meetingDrop(info) {
    let _id = info.event.id;
    let params = {
      "start_date": new Date(info.event.start).toISOString(),
      "end_date": new Date(info.event.end).toISOString()
    }
    // Update Event in background
    this.apiServices.dragMeeting(_id, params).subscribe(res => {
      if (res['status'] == "success") {
        this.apiServices.sendTextMessage(res['msg'], 'success');
      } else {
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
      this.getMeetings();
    });
  }

  onPrevClick() {
    this.prev();
    this.getMeetings();
    this.fullCalendar.getApi().prev();
  }

  onNextClick() {
    this.next();
    this.getMeetings();
    this.fullCalendar.getApi().next();
  }

  prev() {
    if (this.curr_month == 1) {
      this.curr_month = 12;
      this.curr_year = this.curr_year - 1;
    } else {
      this.curr_month = this.curr_month - 1;
    }
  }

  next() {
    if (this.curr_month == 12) {
      this.curr_month = 1;
      this.curr_year = this.curr_year + 1;
    } else {
      this.curr_month = this.curr_month + 1;
    }
  }

  /*  eventResize(info) {
     alert(info.event.title + " end is now " + info.event.end.toISOString());
   }
 
   eventDragStart(timeSheetEntry, jsEvent, ui, activeView) {
     console.log('event drag start');
   }
 
   eventDragStop(timeSheetEntry, jsEvent, ui, activeView) {
     console.log('event drag end');
   } */

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  getMeetings() {
    let params = {
      "month": this.curr_month,
      "year": this.curr_year
    };
    this.apiServices.getMeetings(params).subscribe(res => {
      if (res['status'] == "success") {
        this.total_meetings = res['meetingsCount'];
        this.meetings_data = res['data'];
      } else {
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
    });
  }

}
@Component({
  selector: 'app-speaker-meetings1',
  templateUrl: './add_meeting_popup.html',
  styleUrls: ['./speaker-meetings.component.scss']
})

export class DialogForAddMeeting {

  meeting_id: string;
  meetingForm: FormGroup;
  isEditable = false;
  isModelShow = false;
  isFormSubmit = false;
  modelError = '';

  // Participants and Speakers
  dropDownSpeakers = [];
  selectedSpeakers = [];
  dropDownSpeakerSettings = {};


  // references the #calendar in the template
  @ViewChild('fullcalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(private apiServices: ApiServices,
    public dialogRef: MatDialogRef<DialogForAddMeeting>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
    this.getSpeakers();
    this.dropDownSpeakerSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }

  private initForm() {

    this.modelError = '';
    let title = '';
    let description = '';
    let message = '';
    let speakers = this.fb.array([]);
    let start_date = '';
    let end_date = '';
    let start_time = '';
    let end_time = '';

    // On Event Click
    if (this.data.hasOwnProperty('meeting_data')) {
      this.isEditable = true;
      this.meeting_id = this.data.meeting_data.id;
      title = this.data.meeting_data.title;
      description = this.data.meeting_data.description;
      message = this.data.meeting_data.message;
      this.selectedSpeakers = this.data.meeting_data.speakers;
      start_date = (this.data.meeting_data.start);
      end_date = (this.data.meeting_data.end == "1970-01-01T00:00:00.000Z") ? "" : this.data.meeting_data.end;
    }
    // On Date Click
    if (this.data.hasOwnProperty('date')) {
      start_date = this.data.date;
      end_date = this.data.date;
    }

    this.meetingForm = new FormGroup({
      'title': new FormControl(title, Validators.required),
      'message': new FormControl(message, Validators.required),
      'description': new FormControl(description, Validators.required),
      'speakers': new FormControl(speakers),
      'start_date': new FormControl(start_date, Validators.required),
      'end_date': new FormControl(end_date, Validators.required),
      'start_time': new FormControl(start_time),
      'end_time': new FormControl(end_time),
    });
  }


  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  addMeeting() {
    this.apiServices.cleanForm(this.meetingForm);
    if (this.meetingForm.valid == true) {

      this.isFormSubmit = true;
      // Set speakers
      let speakers = this.selectedSpeakers.map(a => a._id);
      this.meetingForm.value.speakers = speakers;

      // Set time zones
      let start_date_time = (this.meetingForm.value.start_date).toString();
      let end_date_time = (this.meetingForm.value.end_date).toString();

      /* this.meetingForm.value.start_date = new Date(this.meetingForm.value.start_date).toISOString();
      this.meetingForm.value.end_date = new Date(this.meetingForm.value.end_date).toISOString(); */

      // Set Date & Time using Moment
      let startDate = moment(this.meetingForm.value.start_date).tz('Asia/Tokyo').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let endDate = moment(this.meetingForm.value.end_date).tz('Asia/Tokyo').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      this.meetingForm.value.start_date = startDate;
      this.meetingForm.value.end_date = endDate;

      // set start date and time
      let startDateTimeString = start_date_time.split(" ");
      // let startDateString = startDateTimeString[0] + " " + startDateTimeString[1] + " " + startDateTimeString[2] + " " + startDateTimeString[3];
      let startTimeString = startDateTimeString[4];

      // set end date and time
      var endDateTimeString = end_date_time.split(" ");
      // var endDateString = endDateTimeString[0] + " " + endDateTimeString[1] + " " + endDateTimeString[2] + " " + endDateTimeString[3];
      var endTimeString = endDateTimeString[4];

      // this.meetingForm.value.start_date = startDateString;
      this.meetingForm.value.start_time = startTimeString;
      // this.meetingForm.value.end_date = endDateString;
      this.meetingForm.value.end_time = endTimeString;

      this.modelError = '';

      if (this.isEditable) {
        this.apiServices.editMeeting(this.meeting_id, this.meetingForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.onNoClick();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addMeeting(this.meetingForm.value).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.onNoClick();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            this.scrollTop();
          }
        });
      }
    }
    else {
      this.modelError = "Please fill all the required fields";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeMeeting(meeting_id) {
    this.apiServices.deleteMeeting(meeting_id).subscribe(res => {
      if (res['status'] == "success") {
        this.onNoClick();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      } else {
        this.onNoClick();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      }
    });
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

  getSpeakers() {
    this.apiServices.getSpeakers().subscribe(res => {
      if (res['status'] == "success") {
        this.dropDownSpeakers = res['data'];
      }
    });
  }

  onItemSelect1(item: any) {
    console.log(item);
    // console.log(this.selectedSpeakers);
  }
  OnItemDeSelect1(item: any) {
    console.log(item);
    // console.log(this.selectedSpeakers);
  }
  onSelectAll1(items: any) {
    console.log(items);
  }
  onDeSelectAll1(items: any) {
    console.log(items);
  }

  onItemSelect2(item: any) {
    console.log(item);
    // console.log(this.selectedParticipants);
  }
  OnItemDeSelect2(item: any) {
    console.log(item);
    // console.log(this.selectedParticipants);
  }
  onSelectAll2(items: any) {
    console.log(items);
  }
  onDeSelectAll2(items: any) {
    console.log(items);
  }
}

