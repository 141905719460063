
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiServices } from 'src/app/shared/api.services';
import { NgForm } from '@angular/forms';
import { ApiSocket } from 'src/app/shared/api.socket';

@Component({
  selector: 'app-bot-head',
  templateUrl: './bot-head.component.html',
  styleUrls: ['./bot-head.component.css']
})
export class BotHeadComponent implements OnInit {
  base_url: string;
  openChats = [];
  existingChats = [];
  stats = [];
  isParent = true;
  userList: any;
  chat_status: string = "";
  isReady: boolean = false;
  socket: SocketIOClient.Socket;
  thing_type = 'flight';
  search_key: string = '';

  //scroll lazy pagination
  start = 0;
  skip = 0;
  selector: string = '.chat-panel';
  loadUsers: boolean = false;
  isScrollEnd: boolean = false;

  constructor(private apiServices: ApiServices, private router: Router, private apiSocket: ApiSocket) {
    this.base_url = apiServices.base_url;
    this.socket = apiSocket.socket;
  }

  ngOnInit() {
    this.socket.on('bot_flight_open', (open: any) => {
      if (open.by == 'user')
        this.playAudio();
      let id = this.findObjectByKey(this.openChats, 'uniq_id', open['uniq_id']);
      if (id >= 0) {
        this.openChats[id] = open;
      } else {
        this.openChats.push(open);
      }
      this.openChats.sort((a, b) => a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0)
    });
    this.socket.on('bot_flight_existing', (existing: any) => {
      if (existing.by == 'user')
        this.playAudio();
      let id = this.findObjectByKey(this.existingChats, 'uniq_id', existing['uniq_id']);
      if (id >= 0) {
        this.existingChats[id]['message'] = existing['message'];
        this.existingChats[id]['created_at'] = existing['created_at'];
      } else {
        let open_id = this.findObjectByKey(this.openChats, 'uniq_id', existing['uniq_id']);
        if (open_id >= 0) {
          this.openChats.splice(open_id, 1);
        }
        this.existingChats.push(existing);
      }
      this.existingChats.sort((a, b) => a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0)
    });
    this.getChatHeads();
  }

  getChatHeads(skip: number = 0, chat_status: string = '', is_scroll = false) {
    this.router.navigate(['/bot_chat']);
    let params = {};
    this.chat_status = chat_status;
    if (this.chat_status != '') {
      if (this.chat_status == "unsolved") {
        params['solved'] = false;
      } else {
        params['solved'] = true;
      }
    }
    params['skip'] = skip;
    params['thing_type'] = this.thing_type;
    if (this.search_key != '') {
      params['search_key'] = this.search_key;
    }
    this.apiServices.getBotChatHeads(params).subscribe(chats => {
      if (chats['openChats']) {
        this.openChats = chats['openChats'];
      }

      if (chats['existingChats']) {
        if (this.skip > 0 && chats['existingChats'].length == 0) {
          this.isScrollEnd = true;
        } else {
          if (is_scroll == true && this.search_key == '') {
            chats['existingChats'].forEach(chat => {
              this.existingChats.push(chat);
            });
          } else {
            this.existingChats = chats['existingChats'];
          }
          this.isScrollEnd = false;
        }
      }

      if (chats['stats']) {
        this.stats = chats['stats'];
      }
      this.isReady = true;
    });
    this.loadUsers = false;
  }

  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
    return -1;
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "assets/beep.mp3";
    audio.load();
    setTimeout(() => {
      audio.play();
    }, 100);
  }

  onScrollDown() {
    if (this.isScrollEnd == false) {
      this.loadUsers = true;
      this.skip += 10;
      this.getChatHeads(this.skip, this.chat_status, true);
    }

  }

  searchChatHeads(form: NgForm) {
    this.search_key = form.value.search_key;
    this.getChatHeads(0, this.chat_status, true);
  }

}

