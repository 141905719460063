import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiServices } from '../shared/api.services';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate{

    constructor(private apiServices: ApiServices, private router: Router){}

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    //     return this.apiServices.isAuthenticated();
    // }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean> {
        return this.apiServices.isLoggedIn         
          .pipe(
            take(1),                              
            map((isLoggedIn: boolean) => {     
              if (!isLoggedIn){
                //this.apiServices.sendMessage("logged out!", true);
                this.router.navigate(['/signin']);
                return false;
              }
              return true;
            })
          )
      }
}