import { Component, OnInit } from '@angular/core';
import { ApiServices } from '../../shared/api.services';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {
  title = "KYC";
  kyc_users : any = {};
  constructor(public apiService: ApiServices) { }

  ngOnInit() {

    this.kyc_users = this.apiService.getKycUsers().subscribe(kyc_list => {
      this.kyc_users = kyc_list['data'];
    })

  }

}
