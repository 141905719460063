import { Component, OnInit } from '@angular/core';
import { ApiServices } from 'src/app/shared/api.services';

@Component({
  selector: 'app-upload-users',
  templateUrl: './upload-users.component.html',
  styleUrls: ['./upload-users.component.css']
})
export class UploadUsersComponent implements OnInit {

  title = "Users";

  constructor(private apiServices: ApiServices) { }

  ngOnInit() {
  }

  public changeListener(files: FileList) {
    let users = [];
    if (files && files.length > 0) {
      let file: File = files.item(0);
      /* console.log(file);
      console.log(file.name);
      console.log(file.size);
      console.log(file.type); */
      let file_type = file.name.split('.');
      // File size must be greater than 1KB and less than 5MB and File type should be CSV
      if (file_type[file_type.length - 1] == "csv") {
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let data: string = reader.result as string;
          let users = data.split('\n');
          let user_data = [];
          users.slice(1, users.length).forEach(u => {
            let user = u.split(',');
            if(user[73] != '' && user[0] !=''){
              let push = {
                'email_id': user[73],
                'express_id': user[0]
              }
              user_data.push(push);
            }
          });
          // Send data to API
          this.apiServices.userUpload(user_data).subscribe(res => {
            if (res['status'] == "failure") {
              this.apiServices.clearMessage();
              this.apiServices.sendTextMessage(res['msg'], 'danger');
            } else {
              this.apiServices.clearMessage();
              this.apiServices.sendTextMessage(res['msg'], 'success');
            }
          });
        }
      } else {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage('Invalid File type OR File size', 'danger');
      }
    }
  }
}
