import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ApiServices } from './shared/api.services';
import { ApiSocket } from './shared/api.socket';
import { AuthGuard } from './auth/auth-guard.service';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { ProfileComponent } from './users/profile/profile.component';
import { BreadcrumbComponent } from './includes/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './includes/header/header.component';
import { FooterComponent } from './includes/footer/footer.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { LocationsComponent } from './locations/locations.component';
import { LanguagesComponent } from './languages/languages.component';
import { JobsComponent } from './jobs/jobs/jobs.component';
import { JobCategoriesComponent } from './jobs/job-categories/job-categories.component';
import { KycComponent } from './kyc/kyc/kyc.component';
import { PagerService } from '../app/services/pager.service';
import { CompareValidatorDirective } from './shared/compare-validator.directive';
import { TranslateService } from './shared/translate/translate.service';
import { TranslatePipe } from './shared/translate/translate.pipe';
import { NewsComponent } from './news/news/news.component';
import { NewsCategoriesComponent } from './news/news-categories/news-categories.component';
import { ErrorInterceptor } from './shared/error.interceptor';
import { MessageComponent } from './shared/message/message.component';
import { ErrorDataModel } from './shared/message/error.data.model';
import { KycDetailsComponent } from '../app/kyc/kyc-details/kyc-details.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './chat/chat/chat-head/chat.component';
import { ChatChildComponent } from './chat/chat/chat-child/chat-child.component';
import { ChatDefaultComponent } from './chat/chat-default/chat-default.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { EqualValidator } from './shared/equal-validator.directive';
import { MomentModule } from 'ngx-moment';
import { FaqsComponent } from './faqs/faqs.component';
import { PrivacyPolicyComponent } from './privacy/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './privacy/terms-conditions/terms-conditions.component';
import { AboutComponent } from './privacy/about/about.component';
import { BotHeadComponent } from './chat/bot-chat/bot-head/bot-head.component';
import { BotChildComponent } from './chat/bot-chat/bot-child/bot-child.component';
import { UploadUsersComponent } from './users/upload-users/upload-users.component';
// import { VideoBgComponent } from './video-bg/video-bg.component';

// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';

// import { MessagingService } from './shared/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsListComponent } from './notifications/notifications-list/notifications-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FullCalendarTestComponent, DialogForAddEvent } from './full-calendar-test/full-calendar-test.component';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatButtonModule,
  MatCommonModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { AbusedReportsComponent, ReportsOverView } from './abused-reports/abused-reports.component';
import { RequestedReportsComponent, RequestsOverView } from './requested-reports/requested-reports.component';
import { TimeLineListComponent } from './time-line-list/time-line-list.component';
import { InventoryComponent } from './inventory/inventory.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SubscriptionHistoryComponent } from './subscription-history/subscription-history.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { VideoProcessingService } from './services/video-processing-service';
import { TimelineCategoriesComponent } from './timeline-categories/timeline-categories.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeliveryPrefecturesComponent } from './delivery-prefectures/delivery-prefectures.component';
import { EventSpeakersComponent } from './event-speakers/event-speakers.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { SpeakerMeetingsComponent, DialogForAddMeeting } from './speaker-meetings/speaker-meetings.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { CancelRefundComponent } from './cancel-refund/cancel-refund.component';
// import { UploadsComponent } from './uploads/uploads.component';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { ZformComponent } from './zform/zform.component';
import { UserSupportMailComponent } from './settings/user-support-mail/user-support-mail.component';
import { AnnouncementVideosComponent } from './announcement-videos/announcement-videos.component';
import { FeatureToggleComponent } from './feature-toggle/feature-toggle.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import {FileUploadModule} from 'ng2-file-upload';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SigninComponent,
    DashboardComponent,
    UsersComponent,
    ProfileComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    KycComponent,
    LocationsComponent,
    LanguagesComponent,
    JobsComponent,
    JobCategoriesComponent,
    CompareValidatorDirective,
    CompareValidatorDirective,
    TranslatePipe,
    NewsComponent,
    NewsCategoriesComponent,
    MessageComponent,
    KycDetailsComponent,
    ChatComponent,
    ChatChildComponent,
    ChatDefaultComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EqualValidator,
    FaqsComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    BotHeadComponent,
    BotChildComponent,
    UploadUsersComponent,
    NotificationsComponent,
    NotificationsListComponent,
    FullCalendarTestComponent,
    DialogForAddEvent,
    QuestionnaireComponent,
    AbusedReportsComponent,
    RequestedReportsComponent,
    ReportsOverView,
    RequestsOverView,
    TimeLineListComponent,
    InventoryComponent,
    SubscriptionHistoryComponent,
    UserDetailsComponent,
    TimelineCategoriesComponent,
    DeliveryPrefecturesComponent,
    EventSpeakersComponent,
    MeetingsComponent,
    SpeakerMeetingsComponent,
    DialogForAddMeeting,
    ConfigurationsComponent,
    CancelRefundComponent,
    UserSupportMailComponent,
    ZformComponent,
    AnnouncementVideosComponent,
    FeatureToggleComponent,
    // UploadsComponent
    // VideoBgComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AmazingTimePickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDOd6wilA0O6MGdgF_VQuvG4if_hcp7IUU'
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    NgbModule,
    MomentModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    InfiniteScrollModule,
    ImageCropperModule,
    EditorModule,
    MatDialogModule,
    MatButtonModule,
    MatCommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgMultiSelectDropDownModule.forRoot(),
    UploaderModule,
    AngularMultiSelectModule,
    DragDropModule,
    FileUploadModule
  ],
  entryComponents: [
    FullCalendarTestComponent,
    DialogForAddEvent,
    ReportsOverView,
    RequestsOverView,
    SpeakerMeetingsComponent,
    DialogForAddMeeting
  ],

  providers: [ApiServices, ApiSocket, AuthGuard, PagerService, TranslateService, VideoProcessingService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [
        TranslateService
      ],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ErrorDataModel,
    // MessagingService, AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
