import { Component, OnInit, Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiServices } from '../../shared/api.services';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  title = "Admin Profile";
  profile = {};
  isError = false;
  isSuccess = false;
  successMsg = "";
  errorMsg = "";
  base_url: string = '';

  profileForm: FormGroup;
  username: string = '';
  urls = [];

  //Crop Image
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageBlob: any = '';

  @ViewChild('closeBtn') closeBtn: ElementRef;
  constructor(private http: HttpClient, public apiServices: ApiServices, private fb: FormBuilder) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.apiServices.getUserProfile().then(profileData => {
      this.profile = profileData;
      this.initForm();
    })
  }

  initForm() {
    this.username = this.profile['name'];
    let avatar = '';
    if (this.profile['avatar'] != "") {
      avatar = this.profile['avatar'];
      this.urls.push(this.base_url + this.profile['avatar']);
    }

    let imagesValidation = [];
    if (this.urls.length == 0) {
      imagesValidation.push(Validators.required);
    }

    /* this.profileForm = this.fb.group({
      'username': [this.username, Validators.required],
      'avatar': [avatar, imagesValidation]
    }); */

    this.profileForm = new FormGroup({
      'username': new FormControl(this.username, Validators.required),
      'avatar': new FormControl(avatar, imagesValidation),
    });
  }

  // For Single image
  selectedFile: any;
  imgPreview: any;
  onImageSelected(event: any) {
    this.imageChangedEvent = event;
    // For Single image
    this.selectedFile = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (event) => this.imgPreview = reader.result;
      // reader.readAsDataURL(file);
    }
  }

  /* fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  } */

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageBlob = event.file;
  }

  onSubmit() {
    if (this.profileForm.valid == true) {
      const fd = new FormData();
      fd.append('name', this.profileForm.value.username);
      /* let avatar = this.apiServices.blobToFile(this.croppedImageBlob, "avatar");
      fd.append('avatar', avatar, avatar.name); */
      if (this.selectedFile && this.selectedFile.name)
        fd.append('avatar', this.selectedFile, this.selectedFile.name);
      this.apiServices.updateAdminProfile(fd).subscribe(res => {
        if (res['status'] === "failure") {
          /* this.isError = true;
          this.isSuccess = false;
          this.errorMsg = res['msg']; */
          this.apiServices.sendTextMessage(res['msg'], 'danger');
          this.closeBtn.nativeElement.click();
        } else {
          /* this.isError = false;
          this.isSuccess = true;
          this.successMsg = res['msg']; */
          this.apiServices.sendTextMessage(res['msg'], 'success');
          this.closeBtn.nativeElement.click();
          this.ngOnInit();
        }
      });
    }
  }
}
