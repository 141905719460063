import { Component, OnInit, Input } from '@angular/core';
import { ApiServices } from '../../shared/api.services';
import { Observable } from 'rxjs';
// import * as $ from 'jquery';
import * as myjQuery from 'jquery';

import { AppComponent } from '../../app.component';
import { ROUTES } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];

  @Input() profile;
  language:string = '';
  isLoggedIn$: Observable<boolean>;
  constructor(private apiServices: ApiServices, private appComponent: AppComponent) { }

  ngOnInit() {
    this.profile = JSON.parse(localStorage.getItem('session'));
    this.language = localStorage.getItem('lang');
    this.isLoggedIn$ = this.apiServices.isLoggedIn;
    // menu script
    // this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    // $(function() {
    //   $('.btn-toggle-offcanvas').on('click', function() {
    //     if ($('#theme-green').hasClass('offcanvas-active')) {
    //       // alert('class exists');
    //       // $('body').trigger('resize');
    //       $('#theme-green').removeClass('offcanvas-active');
    //     } else {
    //       // alert('class not there');
    //       $('#theme-green').addClass('offcanvas-active');
    //     }
    //   });
    // });

    $(document).ready(function(){
      $("#btn-toggle-offcanvas").click(function(){
        $('body').toggleClass('offcanvas-active');
      });
    });
    
  }

  onLogout(){
    this.apiServices.onLogOut('Logged Out', 'danger');
  }

}
