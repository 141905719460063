import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { ApiServices } from './api.services';

@Injectable()
export class ApiSocket {
    base_url;
    socket: SocketIOClient.Socket;
    constructor(private apiServices: ApiServices) {
        this.base_url = this.apiServices.base_url;
        this.socket = io.connect(this.base_url);
        this.socket.emit('authenticate', { token: apiServices.getAuthToken() });
        this.socket.on('authenticated', function () {
            console.log("authenticated");
        });
    }
}