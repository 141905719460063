import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiServices } from '../shared/api.services';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  title = "FAQS Services";
  base_url;
  ch_faqs: {};
  modelError = '';
  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  faqs_id: string;
  faqForm: FormGroup;
  faqs;
  faqs_data: {} = {};
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;
  constructor(public apiServices: ApiServices) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.getFaqs();
  }
  getFaqsById(_id: string) {
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.faqs_id = _id;
      let params = { '_id': _id };
      this.apiServices.getFaqs(params).subscribe(faq => {
        this.faqs_data = faq['data'][0];
        this.initFrom();
        this.isModelShow = true;
      });
    } else {
      this.isEditable = false;
      this.faqs_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }
  getFaqs() {
    this.apiServices.getFaqs({}).subscribe(faqs_data => {
      this.ch_faqs = faqs_data['data'];
    });
  }
  private initFrom() {
    this.apiServices.clearMessage();
    this.modelError = '';
    let title = '';
    let content = '';
    if (this.isEditable) {
      let faqs = this.faqs_data
      title = faqs['title'];
      content = faqs['content'];
    }
    this.faqForm = new FormGroup({
      'title': new FormControl(title, Validators.required),
      'content': new FormControl(content, Validators.required)
    });
  }

  onSubmit() {
    if (this.faqForm.valid == true) {
      this.modelError = '';
      const fd = new FormData();
      fd.append('title', this.faqForm.value.title);
      fd.append('content', this.faqForm.value.content);
      if (this.isEditable) {
        this.apiServices.editFaqs(this.faqs_id, fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.faqForm.reset();
            // this.getNews(this.filters);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        });
      } else {
        this.apiServices.addFaqs(fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            // this.modelError = res['msg'];
            this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel();
            this.scrollTop();
          }
        });
      }
      this.getFaqs();
    } else {
      this.modelError = "Please select all mandatory(*) values!";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.faqForm.reset();
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

}
