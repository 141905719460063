import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiServices } from '../../shared/api.services';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs';

import { ErrorDataModel } from '../../shared/message/error.data.model';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  constructor(private apiServices: ApiServices, private router: Router, private errorData: ErrorDataModel) { }

  ngOnInit() {
    if (this.apiServices.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }
    this.apiServices.clearMessage();
    if (this.errorData.alerts != undefined) {
      this.errorData.alerts.forEach(alert => {
        this.apiServices.sendTextMessage(alert.message, alert.type);
      });
    }
  }

  onSignin(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    this.apiServices.signinUser(email, password).subscribe(
      data => {
        if (data['status'] === 'failure') {
          this.apiServices.clearMessage();
          this.apiServices.sendTextMessage(data['msg'], 'danger');
        } else {
          // if (data['data']['role'] == 'admin' || data['data']['role'] == 'support' || data['data']['role'] == 'jobManager' || data['data']['role'] == 'kycAdmin') {
          if (data['data']['role'] == 'admin') {
            this.apiServices.clearMessage();
            this.apiServices.sendTextMessage(data['msg'], 'success');
            this.apiServices.saveAuthToken(data['data'])
              .then(savedToken => {
                this.apiServices.getUserProfile()
                  .then(profileData => {
                    this.router.navigate(['/dashboard']);
                  })
              })
          } else {
            this.apiServices.clearMessage();
            this.apiServices.sendTextMessage("Please check login credentials!!", 'danger');
          }
        }
      });
  }
}
