import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiServices } from '../shared/api.services';
import { PagerService } from '../services/pager.service';

@Component({
  selector: 'app-feature-toggle',
  templateUrl: './feature-toggle.component.html',
  styleUrls: ['./feature-toggle.component.css']
})

export class FeatureToggleComponent implements OnInit {

  title = "Feature Toggle";
  base_url: string;
  isAlert = false;
  alertClass = '';
  alertMsg = '';
  modelError = '';

  isEditable = false;
  isModelShow = false;
  isLoaderShow = false;
  isFormSubmit = false;
  feature_id: string;

  featureForm: FormGroup;

  features;
  features_data: {} = {};
  features_list: any;
  tot_features: number;

  //Pagination Variables
  pager: any = {};
  pagedItems: any[];
  pagedItemsLength: number;
  limit: number = 10;

  search_key: string = "";
  pageSize: number = 10;
  offset: number = 0;
  skip: number = 0;

  filters: any = {};

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('alrtDiv') public alrtDiv: ElementRef;

  constructor(public apiServices: ApiServices, private pagerService: PagerService) {
    this.base_url = apiServices.base_url;
  }

  ngOnInit() {
    this.initFrom();
    this.isLoaderShow = false;
    this.setPage(1, this.pageSize);
  }

  fetchFeaturesList(filters: any) {
    return new Promise((resolve, reject) => {
      this.apiServices._fetchFeaturesList(filters).subscribe(features => {
        this.isLoaderShow = true;
        resolve(features);
      })
    });
  }

  fetchFeatureById(_id: string) {
    delete this.filters.skip; delete this.filters.limit;
    this.isModelShow = false;
    if (_id != '') {
      this.isEditable = true;
      this.feature_id = _id;
      let params = {};
      params['_id'] = this.feature_id;
      this.filters['_id'] = this.feature_id;
      this.fetchFeaturesList(params).then(feature_info => {
        this.features_data = feature_info['data'][0];
        this.initFrom();
        this.isModelShow = true;
        return;
      });
    } else {
      this.isEditable = false;
      this.features_data = {};
      this.initFrom();
      this.isModelShow = true;
    }
  }

  private initFrom() {
    this.modelError = '';
    let name = "";
    let activeStatus = '';

    if (this.isEditable) {
      let feature = this.features_data;
      name = feature['name'];
      activeStatus = `${feature['status']}`;
    }
    this.featureForm = new FormGroup({
      'name': new FormControl(name, Validators.required),
      'status': new FormControl(activeStatus)
    });
  }

  onSubmit() {
    this.apiServices.cleanForm(this.featureForm);
    if (this.featureForm.valid == true) {
      this.isFormSubmit = true;
      this.modelError = '';
      const fd = new FormData();

      fd.append('name', this.featureForm.get('name').value);

      if (this.isEditable) {
        fd.append('status', this.featureForm.get('status').value);
        this.apiServices._editFeature(this.feature_id, fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.setPage(this.pager.currentPage, this.pageSize);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
            // this.successAlert(res['msg']);
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            // this.errorAlert(res['msg']);
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
          }
        },
          error => {
            this.errorAlert(error);
          });
      } else {
        this.apiServices._addFeature(fd).subscribe(res => {
          if (res['status'] === 'success') {
            this.isFormSubmit = false;
            this.featureForm.reset();
            this.setPage(this.pager.currentPage, this.pageSize);
            // this.successAlert(res['msg']);
            this.apiServices.sendTextMessage(res['msg'], 'success');
            this.colseModel();
            this.scrollTop();
          } else {
            this.isFormSubmit = false;
            this.modelError = res['msg'];
            // this.errorAlert(res['msg']);
            /* this.apiServices.sendTextMessage(res['msg'], 'danger');
            this.colseModel(); */
            this.scrollTop();
          }
        },
          error => {
            this.errorAlert(error);
          });
      }
    } else {
      this.modelError = "Please fill all the required fields";
      setTimeout(() => {
        this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      });
    }
  }

  colseModel() {
    this.closeBtn.nativeElement.click();
    this.isModelShow = false;
  }

  successAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-success';
    this.colseModel();
  }

  errorAlert(msg: string) {
    this.isAlert = true;
    this.alertMsg = msg;
    this.alertClass = 'alert-danger';
    this.colseModel();
  }

  setPage(page: number, pageCount: number) {
    this.isLoaderShow = false;
    // setting limit and skip values
    if (page > 1) {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = (page - 1) * this.pager.pageSize;
    } else {
      this.filters['limit'] = pageCount;
      this.filters['skip'] = this.skip;
    }

    if (this.filters._id != "" && this.filters._id != null) {
      this.filters['skip'] = (this.pager.currentPage - 1) * this.pager.pageSize;
      delete this.filters._id;
    }

    this.fetchFeaturesList(this.filters).then(featureData => {
      this.features_list = featureData;
      this.isModelShow = true;
      this.isLoaderShow = true;
      return;
    })
      .then(() => {
        // total count
        this.tot_features = this.features_list['total'];
        // users list
        this.features = this.features_list['data'];
        // get pager object from service
        this.pager = this.pagerService.getPager(this.tot_features, page, pageCount);
        // get current page of items
        this.pagedItems = this.features;
        this.pagedItemsLength = this.pagedItems.length;
      })
  }

  scrollTop() {
    setTimeout(() => {
      this.alrtDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    });
  }

}

