import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiServices } from '../api.services';

interface Alert {
    type: string;
    message: string;
}

// const ALERTS: Alert[] = [{
//     type: 'success',
//     message: 'This is an success alert',
// }, {
//     type: 'info',
//     message: 'This is an info alert',
// }, {
//     type: 'warning',
//     message: 'This is a warning alert',
// }, {
//     type: 'danger',
//     message: 'This is a danger alert',
// }, {
//     type: 'primary',
//     message: 'This is a primary alert',
// }, {
//     type: 'secondary',
//     message: 'This is a secondary alert',
// }, {
//     type: 'light',
//     message: 'This is a light alert',
// }, {
//     type: 'dark',
//     message: 'This is a dark alert',
// }
// ];

const ALERTS: Alert[] = [];

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css']
})

export class MessageComponent implements OnDestroy , OnInit{
    alerts: Alert[];
    subscription: Subscription;

    constructor(private apiServices: ApiServices) {
        this.reset();
        this.subscription = this.apiServices.getMessage().subscribe(message => {
            if(message == 'reset'){
                this.reset();
            }else if(message){
                setTimeout(() => this.reset(), 10000);
                this.alerts.push(message);
            }
        });
    }

    ngOnInit(){
        setTimeout(() => this.reset(), 10000);
    }

    close(alert: Alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    reset() {
        this.alerts = Array.from(ALERTS);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
