import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiServices } from '../../shared/api.services';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  title: string = "User Details";
  base_url: string = '';
  public uid: string;
  user_data = {};
  isLoaderShow = false;
  filters: {} = {};

  userDetailsForm: FormGroup = new FormGroup({});

  constructor(private route: ActivatedRoute,
    private apiServices: ApiServices,
    private fb: FormBuilder) {
    this.base_url = this.apiServices.base_url;
    this.uid = this.route.snapshot.paramMap.get('uid');
  }

  ngOnInit() {
    this.isLoaderShow = false;
    this.filters['_id'] = this.uid;
    this.getUserDetails(this.filters).then(user_info => {
      this.user_data = user_info['data'][0];
      this.initForm();
    })
  }

  private initForm() {
    let is_active = 'true';
    let user = this.user_data;
    is_active = `${user['is_active']}`;

    this.userDetailsForm = this.fb.group({
      is_active: [is_active, Validators.required]
    })
  }

  getUserDetails(filters: {}) {
    return new Promise((resolve, reject) => {
      this.apiServices.getUsers(filters).subscribe(users => {
        this.isLoaderShow = true;
        resolve(users);
      })
    })
  }

  onSubmit() {
    let is_active: string;
    is_active = this.userDetailsForm.value.is_active;
    this.apiServices.editUser(this.uid, is_active).subscribe(res => {
      if (res['status'] === 'success') {
        this.apiServices.sendTextMessage(res['msg'], 'success');
        this.ngOnInit();
      } else {
        // this.errorAlert(res['msg']);
        this.apiServices.sendTextMessage(res['msg'], 'danger');
      }
    }, error => {
      this.apiServices.sendTextMessage(error, 'danger');
    });
  }
}
