import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { NgForm } from '@angular/forms';
import { ApiServices } from 'src/app/shared/api.services';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})

export class PrivacyPolicyComponent implements OnInit {

  title = "Privacy Policy of Playfully";
  privacy_policy: string = "";
  config_id: string = "";
  selectedFile: File = null;
  upload_file_url: string = "";
  apiKey = '';

  public model = {
    privacy_policy: ''
  };

  constructor(private apiServices: ApiServices) {
    this.apiKey = apiServices.tinyApiKey;
  }

  ngOnInit() {
    this.getPrivacyPolicy();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  onSubmit(form: NgForm) {
    let data = form.value.text_data;
    this.apiServices.setPrivacyPolicy(data, this.config_id).subscribe(res => {
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      } else {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      }
    })
  }

  getPrivacyPolicy() {
    this.apiServices.getConfigurations().subscribe(res => {
      if (res['status'] === "failure") {
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'failure');
      } else {
        this.model.privacy_policy = res['data']['privacy_policy'];
        this.config_id = res['data']['_id'];
        this.apiServices.clearMessage();
        this.apiServices.sendTextMessage(res['msg'], 'success');
      }
    });
  }

  onFileChanged(event) {
    this.selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append('config_image', this.selectedFile, this.selectedFile.name);
    this.apiServices.uploadFile(fd).subscribe(res => {
      if (res['status'] == "failure") {
        this.apiServices.clearMessage();
      } else {
        this.apiServices.clearMessage();
        this.upload_file_url = res['data'];
        this.apiServices.sendTextMessage(this.upload_file_url, 'success');
      }
    });
  }
  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('copyed text');
  }
}