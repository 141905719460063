import { Component, OnInit } from '@angular/core';

import { AppComponent } from '../../../app/app.component';
import { ApiServices } from '../../shared/api.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.css']
})
export class KycDetailsComponent implements OnInit {

  kycForm: FormGroup;
  user_id : string;
  kyc_status : string;
  title = "KYC Details";

  constructor(private appComponent: AppComponent, private apiServices: ApiServices) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    let first_name = "";
    let laast_name = "";
    let dob = "";
    let address = "";
    let country = "";
    let nationality = "";

    // this.kycForm = new FormGroup({
    //   'first_name': new FormControl(firstName, Validators.required),
    //   'last_name' : new FormGroup(lastName, Validators.required),
    //   'dob' : new FormGroup(date_of_birth, Validators.required),
    //   'address' : new FormGroup(address, Validators.required),

    // })


  }

  changeStatus(id: string, status: string){
    this.user_id = id;
    this.kyc_status = status;
    this.apiServices.changeKycStatus(this.user_id, this.kyc_status).subscribe(res => {
      if (res['status'] === 'success') {
        // this.successAlert(res['msg']);
      } else {
        // this.errorAlert(res['msg']);
      }
    })

  }

  onSubmit(){

  }

}
